import { useEffect, useState } from "react";

//lib
import { Icon } from "@iconify/react";
import MethodInfo from "./method.info";
import Lottie from "lottie-react";
import InputCount from "../../../components/element/input.count";
import { Swiper, SwiperSlide } from "swiper/react";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import { DefaultMenu, EmptyOrder, KueyTeow, Sample } from "../../../assets/images";
import Button from "../../../components/element/button";
import SymbolLine from "../../../components/common/symbol.line";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { getAllPayments } from "../../../redux/reducer/cartReducer";
import { openCongratsModal, openFaqModal, openPaymentModal, openReceiptModal, openReceivePointModal, openRefundModal } from "../../../redux/reducer/modalReducer";
import { getReceiptInfo, setMakePaymentData } from "../../../redux/reducer/orderReducer";
import { Cart } from "../../../assets/lotties";
import { useToast } from "../../../components/common/toast.provider";
import { useNavigate, useParams } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import dayjs from 'dayjs';

//assets

//helper

//element

//component

//redux

export default function OrderBody({ method }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { store } = useParams()

  const { merchantInfo } = useSelector((state) => state.merchant);
  const { orderList, orderListLoading } = useSelector((state) => state.order);

  const [showScrollTop, setShowScrollTop] = useState(false);

  function formatDate(inputDate) {
    const date = new Date(inputDate);

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedDate = `${day} ${monthNames[monthIndex]} ${year}, ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

    return formattedDate;
  }

  const handleOpenReceipt = (id) => {
    dispatch(openReceiptModal())
    dispatch(getReceiptInfo({
        order_id: id
    }))
    // .unwrap()
    // .then((res) => {
    // // if(res.data.result.cashback === 0 
    // //     && !res.data.result.spin_token_amount 
    // //     && !res.data.result.check_in_stamp_amount 
    // //     && !res.data.result.free_promotion_qty) {
    // // } else {
    // // dispatch(openCongratsModal())
    // // }
    // })
  }

  const handleOpenFAQ = (e) => {
    e.stopPropagation()
    dispatch(openFaqModal())
  }

  const handleSubmitRefund = (e, refundId) => {
    e.stopPropagation()
    dispatch(openRefundModal(refundId))
  }

  const sortedOrderList = [...orderList].sort((a, b) => {
    const aIsCompleteAndSuccess = (a.method === 'delivery' && a.delivery_status === 'Delivered') || (a.method !== 'delivery' && a.order_status === 'complete' && a.payment_status === 'success') || a.order_status === 'void';
    const bIsCompleteAndSuccess = (a.method === 'delivery' && b.delivery_status === 'Delivered') || (b.method !== 'delivery' && b.order_status === 'complete' && b.payment_status === 'success') || b.order_status === 'void';
  
    if (aIsCompleteAndSuccess && !bIsCompleteAndSuccess) {
      return 1; // Move 'complete' and 'success' orders to the end
    } else if (!aIsCompleteAndSuccess && bIsCompleteAndSuccess) {
      return -1; // Move 'incomplete' or 'unsuccessful' orders to the top
    } else {
      return 0; // Otherwise, keep the existing order
    }
  });

  const refundMessage = {
    pending: 'Your refund request has been submitted. Please wait for at least 48 hours for further updates.',
    processing: 'Your refund is currently being processed and will be credited back to your original payment method within 24 hours.',
    completed: 'Your refund has been successfully processed, and the amount has been credited back to your original payment method.',
    failed: 'We regret to inform you that your refund has failed.',
  }

  function formatScheduledDate(scheduledAt) {
    const scheduledDay = dayjs(scheduledAt);
    const isToday = scheduledDay.isSame(dayjs(), 'day'); // Check if it's today
    const formattedDay = isToday ? 'Today' : scheduledDay.format('ddd'); // Use 'Today' if it's today

    return `Scheduled at ${formattedDay}, ${scheduledDay.format('h:mm a')}`;
  }

  const handleScrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;

      setShowScrollTop(scrollTop > 500);
    };
  
    window.addEventListener('scroll', handleScroll);

    return () => {
    window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  return (
    <>
        {!orderListLoading && orderList?.length > 0 ? 
        sortedOrderList.map((order, orderIndex) => {
            const isCompleted = (order.method === 'delivery' && order.delivery_status === 'Delivered') || 
            (order.method !== 'delivery' && order.order_status === 'complete' && order.payment_status === 'success') || 
            order.order_status === 'void'

             return (
                <article 
                    key={orderIndex}
                    className={`order-body ${isCompleted ? '--history' : order.method === 'dine in' ? '--dinein' : ''}`} 
                >
                    {!isCompleted && <MethodInfo order={order} />}
                    {order.method === 'dine in' && (order.order_status !== 'complete' || order.payment_status !== 'success') && <h2 className="mb-0">Dine In</h2>}
                    <article>
                        {!isCompleted ?
                          <>
                            <section className={`${order.method !== 'dine in' ? 'mt-4' : 'mt-3'} mb-4`}>
                                <article className={`order-method ${order.method !== 'dine in' ? 'mb-1' : 'mb-3'}`}>
                                    <article className="flex items-center gap-3">
                                        {order.method !== 'dine in' && <h2>{order.method === 'delivery' ? 'Delivery' : 'Self Pick'}</h2>}
                                        <article className="method-col --solid">
                                            <Icon icon="icon-park-outline:transaction-order" />
                                            <h4> {order.order_no}</h4>
                                        </article>
                                        {order.method === 'dine in' && <article className="method-col">
                                            <Icon icon="ic:twotone-table-bar" />
                                            <h4> {order.table_name}</h4>
                                        </article>}
                                    </article>
                                    <article>
                                        <article className={`payment-status ${order.payment_status === 'success' ? '--success' : '--error'}`}>
                                            <h4>{order.payment_status === 'success' ? 'Paid' : 'Unpaid'}</h4>
                                            <Icon 
                                                icon={order.payment_status === "success" ? 
                                                    "solar:bill-check-bold-duotone" 
                                                    : "solar:bill-cross-bold-duotone"} 
                                                color={order.payment_status === "success" ? 
                                                    "#4ab178" : "#dc4c4c"
                                                }
                                            />
                                        </article>
                                    </article>
                                </article>
                                <article className={order.method === 'delivery' || order.method === 'take away' ? "flex justify-between items-baseline mt-2" : ""}>
                                    <h5 className="order_date">{formatDate(order.order_date_time)}</h5>
                                    {(order.method === 'delivery' || order.method === 'take away') && <h5 className="order_date --scheduled_at">{order.schedule_mode === 'scheduled' ? formatScheduledDate(order.scheduled_at) : 'ASAP'}</h5>}
                                </article>
                                {/* {order.scheduled_at ? `Scheduled at ${dayjs(order.scheduled_at).format('ddd, D MMM HH:mm')}`  */}
                            </section>
                            <section className="order-info pointer" onClick={() => order.payment_status === 'success' ? handleOpenReceipt(order.order_id) : undefined}>
                                <article className="order-processing">
                                    <article className="processing-tab gap-3">
                                        <h4 className="text-capitalize">Order {order.order_status === 'void' ? 'Cancelled' : order.order_status}</h4>
                                    </article>
                                    <article className="flex items-center justify-between gap-1">
                                        <article className="order-listing ps-4 pe-4">
                                            {order.order?.length > 0 && order.order.map((orderMenu, orderMenuIndex) => (
                                                <article className="order-food" key={orderMenuIndex}>
                                                    <article className={`order-food-frame ${(!orderMenu.picture && merchantInfo.company_logo.length > 0) ? '--store-logo' : ''}`}>
                                                        <LazyLoadImage src={orderMenu.picture || (merchantInfo.company_logo.length > 0 ? merchantInfo.company_logo[0].url : DefaultMenu)} />
                                                    </article>
                                                    <article>
                                                        <h2 className="food-name two-line">{orderMenu.food_name}</h2>
                                                        <article className="flex items-center flex-wrap">
                                                            {orderMenu.details?.length > 0 ? 
                                                            orderMenu.details.map((orderOption, orderOptionIndex) => (
                                                                <p className="food-details" key={orderOptionIndex}>
                                                                    {orderOption} 
                                                                    {orderOptionIndex !==  orderMenu.details.length - 1 && <span>,&nbsp;</span>}
                                                                </p>
                                                            )) : ''}
                                                        </article>
                                                        {orderMenu.order_remark && <p className="food-details">
                                                            ** {orderMenu.order_remark}
                                                        </p>}
                                                        <p className="food-details --quantity">x{orderMenu.quantity}</p>
                                                    </article>
                                                </article>
                                            ))}
                                        </article>
                                        {order.payment_status === 'success' && <article className="pe-4 view-receipt">
                                            <Icon icon="material-symbols-light:receipt-long" className="right-icon --primary" />
                                            <p>View</p>
                                        </article>}
                                    </article>
                                    {/* <article className="total-amount">
                                        <SymbolLine />
                                        {order.promotion_deduct_amount > 0 && order.promotion_deduct_amount > 0 && 
                                             <>
                                                <article className="order-col mt-4">
                                                    <h4>Subtotal</h4> 
                                                    <h4>{parseFloat(order.subtotal).toFixed(2)}</h4>
                                                </article>
                                                {order.promotion_deduct_amount > 0 && <article className="order-col">
                                                    <h4>Promotion</h4> 
                                                    <h4>- {parseFloat(order.promotion_deduct_amount).toFixed(2)}</h4>
                                                </article>}
                                                {order.promotion_deduct_amount > 0 && <article className="order-col">
                                                    <h4>Referral Discount</h4> 
                                                    <h4>- {parseFloat(order.discount).toFixed(2)}</h4>
                                                </article>}
                                            </>
                                        }
                                        <article className="order-col mt-2">
                                            <h2>Total Amount</h2>
                                            <h2>RM {parseFloat(order.total_price).toFixed(2)}</h2>
                                        </article>
                                    </article> */}
                                </article>
                            </section>
                          </>
                        : 
                          <>
                            <section className="order-info --history pointer" onClick={() => order.payment_status === 'success' ? handleOpenReceipt(order.order_id) : undefined}>
                                <article className={`info-layout ${order.refund_status ? '--refund' : ''}`}>
                                    <section className="order-method --history">
                                        <article className="flex items-center gap-3">
                                            <h2>{order.method === 'dine in' ? 'Dine In' : order.method === 'delivery' ? 'Delivery' : 'Self Pick'}</h2>
                                            <article className="method-col --solid">
                                                <Icon icon="icon-park-outline:transaction-order" />
                                                <h4> {order.order_no}</h4>
                                            </article>
                                            {order.method === 'dine in' && <article className="method-col">
                                                <Icon icon="ic:twotone-table-bar" />
                                                <h4> {order.table_name}</h4>
                                            </article>}
                                        </article>
                                        <article>
                                            <article className={`method-col ${order.order_status === 'void' ? '--error' : '--success'}`}>
                                                <h4>{order.order_status === 'void' ? 'Cancelled' : order.method === 'dine in' ? 'Served' : order.method === 'delivery' ? 'Delivered' : 'Picked Up'}</h4>
                                            </article>
                                        </article>
                                    </section>           
                                    <section>
                                        <h5 className="order_date --history">{formatDate(order.order_date_time)}</h5>
                                    </section>
                                    <section className="flex items-center justify-between gap-4">
                                        <article className="order-listing --history">
                                            <article>
                                                {order.order?.length > 0 && order.order.map((orderMenu, orderMenuIndex) => (
                                                    orderMenuIndex < 2 &&
                                                    <article className="order-food" key={orderMenuIndex}>
                                                        <article className={`order-food-frame ${(!orderMenu.picture && merchantInfo.company_logo.length > 0) ? '--store-logo' : ''}`}>
                                                            <LazyLoadImage src={orderMenu.picture || (merchantInfo.company_logo.length > 0 ? merchantInfo.company_logo[0].url : DefaultMenu)} />
                                                        </article>
                                                        <article>
                                                            <h2 className="food-name two-line">{orderMenu.food_name}</h2>
                                                            <article className="flex items-center flex-wrap">
                                                                {orderMenu.details?.length > 0 ? 
                                                                orderMenu.details.map((orderOption, orderOptionIndex) => (
                                                                    <p className="food-details" key={orderOptionIndex}>
                                                                        {orderOption} 
                                                                        {orderOptionIndex !==  orderMenu.details.length - 1 && <span>,&nbsp;</span>}
                                                                    </p>
                                                                )) : ''}
                                                            </article>
                                                            <p className="food-details --quantity">x{orderMenu.quantity}</p>
                                                        </article>
                                                    </article>
                                                ))}
                                                {order.order?.length - 2 > 0 && <p className="more-item">{order.order?.length - 2} more item(s)</p>}
                                            </article>
                                        </article>
                                        <article className="view-receipt">
                                            <article><Icon icon="material-symbols-light:receipt-long" className="right-icon" /></article>
                                            <p>View</p>
                                        </article>
                                    </section>
                                </article>
                                {order.refund_status && <article className={`refund-status --${order.refund_status}`} onClick={(e) => handleOpenFAQ(e)}>
                                    <section className="flex justify-between items-center gap-3">
                                        <article>
                                            <Icon icon="hugeicons:money-receive-circle" />
                                        </article>
                                        <article>
                                            <p>Refund <span>{order.refund_status}</span>: {refundMessage[order.refund_status]}&nbsp;&nbsp;{order.refund_status !== 'completed' && <span className="learn-more">Learn More</span>}</p>
                                        </article>
                                        {order.refund_status === 'failed' && <Button btnClassName="submit-button" onClick={(e) => handleSubmitRefund(e, order.refund_id)}>Refund</Button>}
                                    </section>
                                </article>}
                            </section>
                          </>
                        }
                    </article>
                </article>
             )
        })
        :
            orderListLoading ?
                <article className={`loading-order ${method === 'dine in' ? '--dine-in' : ''}`}>
                    <article className="lottie-flex">
                        <article className="lottie-frame">
                            <Lottie animationData={Cart} />
                        </article>
                        <article className="flex items-center">
                            <p>Loading</p>
                            <article className="loading-dot ms-2"></article>
                            <article className="loading-dot"></article>
                        </article>
                    </article>
                </article>  
            : 
                <article
                    className={`empty-order`}
                >
                    <section className="grid center">
                    <section className="empty-frame">
                        <LazyLoadImage src={EmptyOrder} />
                    </section>
                    <h2>Your Order is Empty</h2>
                    <p>
                        Looks like you haven't made any order yet ..
                    </p>
                    </section>
                </article>
        }
        <AnimatePresence>
            {showScrollTop && 
                <motion.article
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                        ease: 'easeInOut',
                        type: 'spring',
                        duration: 1.5,
                    }}
                >
                    <motion.button
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0.2 }}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className="scroll-to-top --order"
                        onClick={handleScrollToTop}
                        transition={{
                            ease: 'easeInOut',
                            type: 'spring',
                            duration: 1,
                        }}
                    >
                    <Icon icon="icon-park-outline:to-top" />
                    </motion.button>
                </motion.article>
            }
        </AnimatePresence>
    </>
  );
}

// ${
// method === "dine in" ? "--dine-in" : ""
// }

{/* <article className="order-col">
    <h4>Order No</h4> 
    <h4>{order.order_no}</h4>
</article>
<article className="order-col">
    <h4>Order Time</h4> 
    <h4>{order.order_date_time}</h4>
</article>
{order.method === 'Dine In' && <article className="order-col">
    <h4>Use Time</h4> 
    <h4>1:48:59</h4>
</article>}
<article className="order-col">
    <h4>Order Status</h4>
    <section className="flex justify-center items-center gap-2">
        <h4 className="text-capitalize">{order.order_status}</h4>
    </section>
</article> */}