import useWindowDimensions from "../hook/use.window.dimensions";

import { useDispatch, useSelector } from "react-redux";

import FullModal from "../modal/full.modal.box";
import ModalHeader from "../header/modal.header";
import { closeFaqModal } from "../../redux/reducer/modalReducer";
import { MDBModalBody } from "mdb-react-ui-kit";

export default function Faq() {
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();

    const { isOpenFaqModal } = useSelector((state) => state.modal);
    const { faqList } = useSelector((state) => state.common);

    const handleCloseFaq= () => {
        dispatch(closeFaqModal())
    }

    const handleSendEmail = () => {
        const subject = "Regarding Foodlink FAQ";
        const body = "Dear recipient,\n\nI have a question regarding the foodlink faq. Can we discuss further?\n\nRegards,\n[Your Name]";
        const mailtoUrl = `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    
        window.location.href = mailtoUrl;
    };
    
    return (
        <FullModal
            show={isOpenFaqModal}
            backButton={handleCloseFaq}
            screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
            contentClassName="--white"
            content={
                <>
                    <ModalHeader 
                        title="Frequently Asked Questions" 
                        backTo={handleCloseFaq} 
                        backToNoAnimation={handleCloseFaq} 
                        type="model2"
                    />
                    <MDBModalBody>
                        <article className="spin-modal">
                            <section className="list-section">
                                <p>Welcome to FoodLink's Help Center! Below you'll find answers to some of the most frequently asked questions about using our platform. Whether you're placing an order, managing vouchers, or requesting refunds, we've got you covered.</p>
                                <ul>
                                {Object.keys(faqList)?.length > 0 && Object.keys(faqList).map((sectionKey, sectionIndex) => (
                                    <li key={sectionIndex}>
                                        <article>
                                        <h5>{sectionKey}</h5>
                                        <ol>
                                            {faqList[sectionKey]?.length > 0 && faqList[sectionKey].map((faq, faqIndex) => (
                                            <li key={faqIndex}>
                                                <article className="ms-2">
                                                <h5>{faq.title}</h5>
                                                <p>{faq.content}</p>
                                                </article>
                                            </li>
                                            ))}
                                        </ol>
                                        </article>
                                    </li>
                                    ))}
                                </ul>
                                <p>For any questions or concerns, please reach out to our customer support team at <span onClick={() => handleSendEmail()}>{process.env.REACT_APP_SUPPORT_EMAIL}</span>. We are here to assist you with any inquiries or issues you may have.</p>
                                <p>We appreciate your continued support of FoodLink! We hope you enjoy the benefits and convenience of using our platform. Thank you for being a valued user!</p>
                            </section>
                        </article>
                    </MDBModalBody>
                </>
            }
        />
    );
}