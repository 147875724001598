import { useEffect, useRef, useState } from "react";

import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Icon } from "@iconify/react";

import { useToast } from "../../../components/common/toast.provider";

import { useDispatch, useSelector } from "react-redux";

import Input from "../../../components/element/input";
import Button from "../../../components/element/button";
import { createReservation, getReservationInfo, getReservationTime, getTableSection } from "../../../redux/reducer/reserveReducer";
import dayjs from 'dayjs';
import { getReserveData, setReserveId } from "../../../redux/reducer/commonReducer";

export default function ReservationBody({ method }) {
  const toast = useToast();
  const dispatch = useDispatch()

  const dropExpRef = useRef(null);
  const dropTableRef = useRef(null);
  const dropTimeRef = useRef(null);
  const dropSessionRef = useRef(null);

  const formikRef = useRef(null);

  const { merchantInfo } = useSelector((state) => state.merchant);
  const { merchantId, reserveId, reserveData, username } = useSelector((state) => state.common);
  const { experienceList, tableSectionList, reserveTimeList, timeSessionList, reserveInfo, sessionLoading, tableLoading, expLoading, reserveLoading, infoLoading, timeLoading } = useSelector((state) => state.reserve);

  const [ dropExp, setDropExp ] = useState(false);
  const [ dropTable, setDropTable ] = useState(false);
  const [ dropTime, setDropTime ] = useState(false);
  const [ dropSession, setDropSession ] = useState(false);

  const [ timeList, setTimeList ] = useState([]);
  const [ noSlotAvailable, setNoSlotAvailable ] = useState(false);

  const [ initial, setInitial ] = useState(true);

  const getNextAvailableDate = (startDate) => {
    const unavailableDays = {
      0: merchantInfo.is_unavailable_on_sunday,
      1: merchantInfo.is_unavailable_on_monday,
      2: merchantInfo.is_unavailable_on_tuesday,
      3: merchantInfo.is_unavailable_on_wednesday,
      4: merchantInfo.is_unavailable_on_thursday,
      5: merchantInfo.is_unavailable_on_friday,
      6: merchantInfo.is_unavailable_on_saturday
    };
  
    let nextDate = startDate;
    while (unavailableDays[nextDate.day()] === 1) {
      nextDate = nextDate.add(1, 'day');
    }
  
    return nextDate;
  };
  
  const initialDate = merchantInfo.accept_reservation_today === 1 ? dayjs() : dayjs().add(1, 'day');
  const reservationDate = getNextAvailableDate(initialDate).format('DD-MM-YYYY');

  const reserveForm = {
    merchant_id: merchantId,
    name: '',
    code: '+60',
    phone_number: username && username !== "null" ? username?.substring(3) : '',
    reservation_date: reservationDate,    
    child_pax: '0',
    adult_pax: 1,
    reservation_time: '',
    remark: '',
    reservation_service_id: '',
    time_session: timeSessionList?.length > 0 ? timeSessionList[0].name : '',
    // experience_id:  experienceList?.length > 0 ? experienceList[0].id : '',
    // table_section_id: tableSectionList?.length > 0 ? tableSectionList[0].id : '',
  }

  const reserveSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    phone_number: Yup.string()
      .required("Phone number is required")
      .matches(/^(1)[0-9]{8,9}$/,
      "Enter a valid international phone number (e.g., 123456789)"
    ),
    time_session: Yup.string().required("Time session is required"),
    adult_pax: Yup.string().required("Adult pax is required"),
    // experience_id: Yup.string().required("Experience is required"),
    // table_section_id: Yup.string().required("Table section is required"),
    reservation_date: Yup.string().required("Reservation date is required"),
    reservation_time: Yup.string().required("Reservation time is required"),
  });

  const handleChoose = (name, value, setFieldValue, values) => {
    // if(name === "experience_id") {
    //     if(values['experience_id'] !== id ) {
    //         if(values['reservation_time']) {
    //             setFieldValue("reservation_time", "")
    //         }

    //         setTimeList([])
            
    //         dispatch(getTableSection({
    //             merchant_id: merchantId,
    //             experience_id: id
    //         }))
    //         .unwrap()
    //         .then((res) => {
    //             if(res.data.length > 0) {
    //                 if(values["table_section_id"]) {
    //                     setFieldValue('table_section_id', res.data[0].id)
    //                 }
    
    //                 dispatch(getReservationTime({
    //                     merchant_id: merchantId,
    //                     experience_id: id,
    //                     table_section_id: res.data[0].id,
    //                 }))
    //             }
    //         })
    //     }
    // } else if (name === "table_section_id") {
    //     if(values['table_section_id'] !== id ) {
    //         if(values['reservation_time']) {
    //             setFieldValue("reservation_time", "")
    //         }

    //         setTimeList([])

    //         dispatch(getReservationTime({
    //             merchant_id: merchantId,
    //             experience_id: values['experience_id'],
    //             table_section_id: id,
    //         }))
    //     }
    // }

    if(name === "time_session") {
        setFieldValue("reservation_time", "")
        setTimeList([])

        dispatch(getReservationTime({
            merchant_id: merchantId,
            service_name: value
        }))
    }

    setFieldValue(name, value)

    setDropExp(false)
    setDropTable(false)
    setDropTime(false)
    setDropSession(false)
  }

  const handleClickOutside = (event) => {
    if (dropExpRef.current && !dropExpRef.current.contains(event.target)) {
        setDropExp(false);
    }

    if (dropTableRef.current && !dropTableRef.current.contains(event.target)) {
        setDropTable(false);
    }    

    if (dropTimeRef.current && !dropTimeRef.current.contains(event.target)) {
        setDropTime(false);
    }  
    
    if (dropSessionRef.current && !dropSessionRef.current.contains(event.target)) {
        setDropSession(false);
    }   
  };

  function formatDate(dateString) {
    const date = dayjs(dateString, 'DD/MM/YYYY');

    return date.format('YYYY-MM-DD');
  }

  function isReservationOver(reservationTime) {
    const reservationDate = dayjs(reservationTime);
    const now = dayjs();
  
    return reservationDate.isBefore(now);
  }

  function generateTimeSlots(timeRanges, reservationDate, operatingHour, setNoSlotsAvailable) {
    const interval = 15;
    let slots = [];
    const now = dayjs();
    const isReservationToday = dayjs(reservationDate, 'DD/MM/YYYY').isSame(dayjs(), 'day');

    timeRanges?.length > 0 && timeRanges.forEach(range => {
        let startTime = dayjs(range.from_time, "HH:mm:ss");
        let endTime = dayjs(range.to_time, "HH:mm:ss");

        if (endTime.isBefore(startTime)) {
            endTime = endTime.add(1, 'day');
        }

        operatingHour?.length > 0 && operatingHour.forEach(hourRange => {
            let [operatingStart, operatingEnd] = hourRange.split('-').map(time => dayjs(time, "HH:mm"));

            if (operatingEnd.isBefore(operatingStart)) {
                operatingEnd = operatingEnd.add(1, 'day');
            }

            const overlapStart = startTime.isBefore(operatingStart) ? operatingStart : startTime;
            const overlapEnd = endTime.isAfter(operatingEnd) ? operatingEnd : endTime;
            
            if (overlapStart.isBefore(overlapEnd) || overlapStart.isSame(overlapEnd)) {
                let currentTime = overlapStart;

                if (isReservationToday && currentTime.isBefore(now)) {
                    currentTime = now.add(interval - (now.minute() % interval), 'minute');
                }

                while (currentTime.isBefore(overlapEnd) || currentTime.isSame(overlapEnd)) {
                    if (!isReservationToday || currentTime.isAfter(now)) { 
                        const time24hr = currentTime.format("HH:mm");
                        const time12hr = currentTime.format("h:mm A");
                        slots.push({ label: time12hr, value: time24hr });
                    }

                    if (currentTime.add(interval, 'minute').isAfter(overlapEnd) && overlapEnd.isBefore(currentTime.add(interval, 'minute'))) {
                        const endTimeSlot = overlapEnd.format("HH:mm");
                        const endTimeLabel = overlapEnd.format("h:mm A");
                        slots.push({ label: endTimeLabel, value: endTimeSlot });
                        break;
                    }

                    currentTime = currentTime.add(interval, 'minute');
                }
            }
        });
    });

    const uniqueSlots = slots.reduce((acc, current) => {
        const x = acc.find(item => item.value === current.value);
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);

    // if (isReservationToday && uniqueSlots?.length === 0 && !timeLoading) {
    //     setNoSlotAvailable(true);
    //     formikRef?.current?.setFieldValue('reservation_date', getNextAvailableDate(dayjs().add(1, 'day')).format('DD-MM-YYYY'));
    // } else {
    //     setNoSlotAvailable(false);
    //     formikRef?.current?.setFieldValue('reservation_date', dayjs().format('DD-MM-YYYY'));
    // }

    return uniqueSlots.sort((a, b) => dayjs(a.value, "HH:mm").diff(dayjs(b.value, "HH:mm")));
  }

  const handleMakeAnother = () => {
    let existingReserveData = reserveData || [];

    const filteredReserveData = existingReserveData.filter(item => item.merchant_id !== merchantId);
    localStorage.setItem('reserve_data', JSON.stringify(filteredReserveData));

    dispatch(getReserveData());
    dispatch(setReserveId(''))
  }

  const handleSubmit = async ({ values, errors, setFieldError, resetForm }) => {
    dispatch(createReservation({
        merchant_id: values.merchant_id,
        name: values.name,
        service_name: values.time_session,
        // experience_id: values.experience_id,
        // table_section_id: values.table_section_id,
        pax: parseInt(values.child_pax ?? 0) + parseInt(values.adult_pax),
        child_pax: parseInt(values.child_pax ?? 0),
        adult_pax: parseInt(values.adult_pax),
        reservation_time: `${formatDate(values.reservation_date)} ${values.reservation_time}:00`,
        phone_no: `+60${values.phone_number}`,
        remark: values.remark
    }))
    .unwrap()
    .then((res) => {
        resetForm();
        dispatch(setReserveId(res.data.id))

        const newReserveData = { merchant_id: merchantId, reserve_id: res.data.id }; 
        let existingReserveData = reserveData || [];
    
        let updatedReserveData = existingReserveData.map(item => 
            item.merchant_id === merchantId 
                ? { ...item, reserve_id: res.data.id } 
                : item
        );

        const isMerchantExisting = updatedReserveData.some(item => item.merchant_id === merchantId);
    
        if (!isMerchantExisting) {
            updatedReserveData = [...updatedReserveData, newReserveData];
        }
    
        localStorage.setItem('reserve_data', JSON.stringify(updatedReserveData));
        dispatch(getReserveData())

        toast.success('Reserve table successful.')
    })
    .catch((ex) => {
        if (ex && ex.response?.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
                Object.keys(errors).map((item, i) => {
                    setFieldError(item, errors[item][0]);
                });
            }
        }
    });
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropExp, dropTable, dropTime, dropSession]);

  useEffect(() => {
    if(merchantId && initial && !sessionLoading) {
        // if(experienceList?.length > 0) {
        //     formikRef?.current?.setFieldValue('experience_id', experienceList[0].id)
        // }
    
        // if(tableSectionList?.length > 0) {
        //     formikRef?.current?.setFieldValue('table_section_id', tableSectionList[0].id)
        // }

        // dispatch(getReservationTime({
        //     merchant_id: merchantId,
        //     experience_id:  experienceList?.length > 0 ? experienceList[0].id : '',
        //     table_section_id: tableSectionList?.length > 0 ? tableSectionList[0].id : '',
        // }))

        if(timeSessionList?.length > 0) {
            formikRef?.current?.setFieldValue('time_session', timeSessionList[0].name)
            setInitial(false)
        }
    }
  }, [merchantId, sessionLoading])

  useEffect(() => {
    if(!timeLoading && reserveTimeList?.length > 0) {
        const timeSlots = generateTimeSlots(reserveTimeList[0].available_times, formikRef?.current?.values?.reservation_date, merchantInfo.operating_hour);

        setTimeList(timeSlots)
    }
  }, [formikRef?.current?.values?.reservation_date, reserveTimeList, timeLoading])

  useEffect(() => {
    if(merchantId && reserveId && reserveId !== "null") {
        dispatch(getReservationInfo({
            reservation_id: reserveId,
            merchant_id: merchantId
        }))
    }
  }, [merchantId, reserveId])

  return (
    <section className="reservation-body">
        {/* {merchantInfo.store_status === 'unavailable' && <article className="notification">
            <Icon icon="carbon:error" />
            <p>This store is <span>temporarily closed</span>, please try again later.</p>
        </article>} */}
        <article className='current-line-section'>
            <article className="current-line-text">
                <p>{reserveId ? 'Reservation Details' : 'Table Reservation'}</p>
            </article>
        </article>
        <Formik
            innerRef={formikRef}
            initialValues={reserveForm}
            validationSchema={reserveSchema}
            onSubmit={(values, { setFieldError, resetForm }) => {
                handleSubmit({ values, setFieldError, resetForm });
            }}
        >
            {({
                errors,
                setFieldValue,
                values
            }) => (
                <Form>
                    {reserveId && reserveId !== "null" ?
                     infoLoading || (initial && sessionLoading) ?
                        <article className="general-loading mt-3">
                            <Icon icon="svg-spinners:12-dots-scale-rotate" color="#6c5fe680" />
                        </article>
                     :
                        <article className="your-line-section">
                            <article className="title">
                                <Icon className={`icon --${reserveInfo?.status}`} icon={reserveInfo?.status === 'rejected' ? "ic:baseline-cancel" : "mdi:tick-circle"} />
                                <h1>{reserveInfo?.status === 'rejected' ? 'Reservation is Cancelled' : 'Table Reserved Successful'}</h1>
                            </article>
                            <hr />
                            <section className="your-line-info">
                                <article className="line-col --main">
                                    <h6>{reserveInfo?.reservation_service_name}</h6>
                                    <p>Time Session</p> 
                                    {/* //reserveInfo?.table_section_name */}
                                </article>
                                <article className="line-col">
                                    <p>Name</p>
                                    <h6>{reserveInfo?.name}</h6>
                                </article>
                                <article className="line-col">
                                    <p>Contact No.</p>
                                    <h6>{reserveInfo?.phone_no}</h6>
                                </article>
                                <article className="line-col">
                                    <p>Date Time</p>
                                    <h6>{reserveInfo?.reservation_time ? dayjs(reserveInfo.reservation_time).format('ddd, D MMM h:mm A') : ''}</h6>
                                </article>
                                <article className="line-col">
                                    <p>Total Pax</p>
                                    <h6>{reserveInfo?.no_of_pax}</h6>
                                </article>
                            {reserveInfo?.remark && <article className="line-col --grey">
                                    <Icon icon="hugeicons:sticky-note-01" />
                                    <p>{reserveInfo?.remark}</p>
                                </article>}
                                {/* <Button variant="outline" className="grid center" btnClassName="refresh-btn">Refresh</Button> */}
                            </section>
                            {reserveInfo?.reservation_time && (isReservationOver(reserveInfo?.reservation_time) || reserveInfo?.status === 'rejected') ?
                                <>
                                    <hr />
                                    <Button className="ps-5 pe-5 pt-3" btnClassName="text-uppercase w-100" type="button" onClick={() => handleMakeAnother()}>
                                        {reserveInfo?.status === 'rejected' ? 'Make Another Reservation' : 'Complete Reservation'}
                                    </Button>
                                </>
                                : ''
                            }
                        </article>
                    :
                        <article className="input-section">
                            <Input
                                name="name"
                                placeholder="Name"
                                isRequired={true}
                                // disabled={merchantInfo.store_status === 'unavailable'}
                            />          
                            <section className={`input-flex ${errors && errors['phone_number'] ? '--error' : '--no-error'}`}>   
                                <Input
                                    readOnly
                                    name="code"
                                    placeholder="Code"
                                />
                                <Input
                                    type="code"
                                    isRequired={true}
                                    name="phone_number"
                                    placeholder="Mobile Number"
                                    // disabled={merchantInfo.store_status === 'unavailable'}
                                />
                                <></>
                            </section>    
                            {errors && errors['phone_number'] && 
                                <div className='element _errors text-right no-padding'>
                                    {errors['phone_number']}
                                </div>
                            }
                            <section className={`input-flex pax ${errors && errors['adult_pax'] ? '--error' : '--no-error'}`}>   
                                <Input
                                    type="number"
                                    isRequired={true}
                                    name="adult_pax"
                                    placeholder="Adult Pax"
                                    // disabled={merchantInfo.store_status === 'unavailable'}
                                />
                                <Input
                                    type="number"
                                    name="child_pax"
                                    placeholder="Child Pax"
                                    // disabled={merchantInfo.store_status === 'unavailable'}
                                />
                            </section>    
                            {errors && errors['adult_pax'] && 
                                <div className='element _errors text-right no-padding'>
                                    {errors['adult_pax']}
                                </div>
                            }
                            {/* {experienceList?.length > 0 && <section className="relative">
                                <Input
                                    name="experience_id"
                                    placeholder="Dining Experience"
                                    readOnly
                                    value={experienceList.find((exp) => exp.id === values['experience_id'])?.name}
                                    isRequired={true}
                                    onClick={() => setDropExp(!dropExp)}
                                    // disabled={merchantInfo.store_status === 'unavailable'}
                                />
                                <article 
                                    ref={dropExpRef}
                                    className={`dropdown-container ${errors && errors['experience_id'] ? '--error' : '--reserve'} ${!dropExp ? 'hide' : ''}`}
                                >
                                    {experienceList?.map((exp, expIndex) => (
                                        <section 
                                            className="row pointer"
                                            onClick={() => handleChoose('experience_id', exp.id, setFieldValue, values)}
                                            key={expIndex}
                                        >
                                            <label className="pointer">{exp.name}</label>
                                        </section>
                                    ))}
                                </article>
                            </section>}
                            {tableSectionList?.length > 0 && <section className="relative">
                                <Input
                                    name="table_section_id"
                                    placeholder="Table Section"
                                    isRequired={true}
                                    value={tableSectionList.find((table) => table.id === values['table_section_id'])?.name}
                                    onClick={() => setDropTable(!dropTable)}
                                    readOnly
                                    disabled={tableLoading}
                                />
                                <article 
                                    ref={dropTableRef}
                                    className={`dropdown-container ${errors && errors['table_section_id'] ? '--error' : '--reserve'} ${!dropTable ? 'hide' : ''}`}
                                >
                                    {tableSectionList?.map((table, tableIndex) => (
                                        <section 
                                            className="row pointer"
                                            onClick={() => handleChoose('table_section_id', table.id, setFieldValue, values)}
                                            key={tableIndex}
                                        >
                                            <label className="pointer">{table.name}</label>
                                        </section>
                                    ))}
                                </article>
                            </section>} */}
                            {timeSessionList?.length > 0 && <section className="relative">
                                <Input
                                    name="time_session"
                                    placeholder="Time Session"
                                    isRequired={true}
                                    value={values['time_session']}
                                    onClick={() => setDropSession(!dropSession)}
                                    readOnly
                                    disabled={sessionLoading}
                                />
                                <article 
                                    ref={dropSessionRef}
                                    className={`dropdown-container ${errors && errors['time_session'] ? '--error' : '--reserve'} ${!dropSession ? 'hide' : ''}`}
                                >
                                    {timeSessionList?.map((reserve, reserveIndex) => (
                                        <section 
                                            className="row pointer"
                                            onClick={() => handleChoose('time_session', reserve.name, setFieldValue, values)}
                                            key={reserveIndex}
                                        >
                                            <label className="pointer">{reserve.name}</label>
                                        </section>
                                    ))}
                                </article>
                            </section>}
                            <Input
                                readOnly
                                noSlotAvailable={noSlotAvailable}
                                name="reservation_date"
                                placeholder="Reservation Date"
                                type="reserve-date"
                                inputClassName="--date"
                                isRequired={true}
                                values={''}
                                disabled={timeLoading}
                            />
                            <section className="relative">
                                <Input
                                    name="reservation_time"
                                    placeholder="Reservation Time"
                                    isRequired={true}
                                    value={timeList.find((time) => time.value === values['reservation_time'])?.label}
                                    onClick={() => setDropTime(!dropTime)}
                                    readOnly
                                    disabled={timeLoading || sessionLoading || !values.reservation_date}
                                />
                                <article 
                                    ref={dropTimeRef}
                                    className={`dropdown-container ${timeList?.length === 0 ? '--empty' : ''} ${errors && errors['reservation_time'] ? '--error' : '--reserve'} ${!dropTime ? 'hide' : ''}`}
                                >
                                    {timeList?.length > 0 ? timeList.map((time, timeIndex) => (
                                        <section 
                                            className="row pointer"
                                            onClick={() => handleChoose('reservation_time', time.value, setFieldValue, values)}
                                            key={timeIndex}
                                        >
                                            <label className="pointer">{time.label}</label>
                                        </section>
                                    ))
                                    :
                                        <section className="row">
                                            <label>No Slot Available</label>
                                        </section>
                                    }
                                </article>
                            </section>
                            <Input
                                name="remark"
                                placeholder="Remark"
                            />
                            <section> 
                                <p className="note-text ms-2 mt-4">Note: Please enter valid mobile number to ensure you receive table reservation message.</p>
                                <Button type="submit" className="w-100 pt-2" btnClassName="w-100 text-uppercase" disabled={reserveLoading}>Reserve Now</Button>
                            </section>
                        </article>
                    }
                </Form>
            )}
        </Formik>
    </section>
  );
}
