import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import dayjs from 'dayjs';
import Pusher from "pusher-js";

import scrollToTop from "../../helpers/scroll.to.top";

import { useDispatch, useSelector } from "react-redux";
import {
  getAddOnMenu,
  getCartInfo,
  getDeliveryTime,
  getMyPromotion,
  setPaymentMethod,
  setPaymentStatus,
  setPickupDate,
  setPickupTime,
  setUpdateCartId,
} from "../../redux/reducer/cartReducer";
import {
  getCartId,
  getCartOwner,
  getGuest,
  getLogin,
  getMethod,
  getSessionNo,
  getToken,
  getUserId,
  getUsername,
  getVoucherPopup,
  setFullLoading,
} from "../../redux/reducer/commonReducer";
import { getRecommendMenu } from "../../redux/reducer/menuReducer";
import { getAddressInfo } from "../../redux/reducer/addressReducer";

import Login from "../Login";
import CartBody from "./components/cart.body";
import CartHeader from "./components/cart.header";
import InstantTopUp from "./modals/instant.top.up";
import SelectPayment from "./modals/select.payment";
import SelectVoucher from "./modals/select.voucher";
import PlaceOrder from "../Menu/modals/place.order";
import StoreInfo from "../Merchant/modals/store.info";
import PromoDetails from "../../components/common/promo.details";
import { openVoucherModal } from "../../redux/reducer/modalReducer";
import SelectTime from "./modals/select.time";
import Faq from "../../components/common/faq";

export default function Cart() {
  const { store } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    sessionNo,
    token,
    token_checked,
    username,
    userId,
    addressId,
    merchantId,
    cartId,
    voucherPopup,
    login,
    voucherPopup_checked
  } = useSelector((state) => state.common);
  const {
    isOpenTimeModal,
    isOpenPaymentModal,
    isOpenVoucherModal,
    isOpenStoreInfoModal,
    isOpenLoginModal,
    isOpenPlaceOrderModal,
    isOpenPromotionDetailsModal,
    isOpenInstantTopUpModal,
    isOpenFaqModal
  } = useSelector((state) => state.modal);
  const { cartInfo, pickupDate, pickUpTime } = useSelector((state) => state.cart);
  const { merchantInfo } = useSelector((state) => state.merchant);

  const [loginType, setLoginType] = useState("");

  const method = localStorage.getItem("method");

  function getTwoDays() {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const today = new Date();
    
    const unavailableDays = {
      Sun: merchantInfo.is_unavailable_on_sunday,
      Mon: merchantInfo.is_unavailable_on_monday,
      Tue: merchantInfo.is_unavailable_on_tuesday,
      Wed: merchantInfo.is_unavailable_on_wednesday,
      Thu: merchantInfo.is_unavailable_on_thursday,
      Fri: merchantInfo.is_unavailable_on_friday,
      Sat: merchantInfo.is_unavailable_on_saturday,
    };
  
    const twoDays = [0, 1].map(offset => {
      const date = new Date(today);
      date.setDate(today.getDate() + offset);
  
      const dayOfWeek = daysOfWeek[date.getDay()];
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const dayOfMonth = String(date.getDate()).padStart(2, '0');
      const fullDate = `${year}-${month}-${dayOfMonth}`;
  
      if (unavailableDays[dayOfWeek] === 1) {
        return null;
      }
  
      return {
        date: date.getDate(),
        day: offset === 0 ? 'Today' : dayOfWeek,
        full_date: fullDate
      };
    }).filter(day => day !== null);
    return twoDays;
  }

  const twoDays = getTwoDays()

  function generatePickUpTimeList(operationHours, afterDurationMinutes, days) {
    const interval = 30;
    let firstTimeSlotFound = false;

    return days.map((day, dayIndex) => {
      let availableTimes = [];
      const currentTime = new Date();
      // currentTime.setHours(3);
      // currentTime.setMinutes(1);
      // currentTime.setSeconds(0);
      // currentTime.setMilliseconds(0);

      const earliestTime = new Date(
        currentTime.getTime() + afterDurationMinutes * 60000
      );

      if(operationHours?.length > 0) {
        operationHours.forEach((period) => {
          let [start, end] = period.split("-");
          let startDateTime = new Date(`${day.full_date}T${start}:00`);
          let endDateTime = new Date(`${day.full_date}T${end}:00`);
  
          if (endDateTime <= startDateTime) {
            endDateTime.setDate(endDateTime.getDate() + 1);
          }
  
          let currentSlot;
  
          if (earliestTime >= startDateTime && earliestTime <= endDateTime) {
            currentSlot = new Date(earliestTime);
            currentSlot.setMinutes(
              Math.ceil(currentSlot.getMinutes() / interval) * interval,
              0,
              0
            );
          } else if (earliestTime < startDateTime) {
            currentSlot = new Date(
              startDateTime.getTime() + afterDurationMinutes * 60000
            );
            currentSlot.setMinutes(
              Math.ceil(currentSlot.getMinutes() / interval) * interval,
              0,
              0
            );
          } else {
            return;
          }
  
          while (currentSlot <= endDateTime) {
            const timeString = currentSlot.toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
            });
            const time24HrFormat = currentSlot.toTimeString().slice(0, 5);
            const fromNowMinutes = Math.round(
              (currentSlot - currentTime) / 60000
            );
            const fromNowText =
              fromNowMinutes >= 60
                ? `${Math.floor(fromNowMinutes / 60)} hrs ${
                    fromNowMinutes % 60
                  } mins`
                : `${fromNowMinutes} mins`;
  
            if (
              dayIndex === 0 &&
              time24HrFormat === "00:00" &&
              currentTime.getHours() >= 3
            ) {
              currentSlot = new Date(currentSlot.getTime() + interval * 60000);
              continue;
            }
  
            availableTimes.push({
              time: timeString,
              from_now: fromNowText,
              "24hr_format": time24HrFormat,
              asap: !firstTimeSlotFound && dayIndex === 0,
            });
  
            if (!firstTimeSlotFound && dayIndex === 0) {
              firstTimeSlotFound = true;
            }
  
            currentSlot = new Date(currentSlot.getTime() + interval * 60000);
          }
        });
      }

      // availableTimes = availableTimes.sort((a, b) => {
      //   let timeA = new Date(${day.full_date} ${a["24hr_format"]}:00);
      //   let timeB = new Date(${day.full_date} ${b["24hr_format"]}:00);
      //   return timeA - timeB;
      // });

      // if (
      //   dayIndex > 0 &&
      //   availableTimes.length > 0 &&
      //   availableTimes[0]["24hr_format"] === "00:00"
      // ) {
      //   availableTimes.shift();
      //   availableTimes.push({
      //     time: "12:00 AM",
      //     from_now: "1 day 0 hrs",
      //     "24hr_format": "00:00",
      //     asap: false,
      //   });
      // }
      availableTimes = availableTimes.sort((a, b) => {
        let timeA = new Date(`${day.full_date} ${a["24hr_format"]}:00`);
        let timeB = new Date(`${day.full_date} ${b["24hr_format"]}:00`);

        if (
          a["24hr_format"] === "00:00" ||
          parseInt(a["24hr_format"].split(":")[0]) < 6
        ) {
          timeA.setDate(timeA.getDate() + 1);
        }
        if (
          b["24hr_format"] === "00:00" ||
          parseInt(b["24hr_format"].split(":")[0]) < 6
        ) {
          timeB.setDate(timeB.getDate() + 1);
        }

        return timeA - timeB;
      });

      return {
        day: day.day,
        date: day.date,
        full_date: day.full_date,
        times: availableTimes,
      };
    });
  }

  const pickUpTimeList = generatePickUpTimeList(merchantInfo.operating_hour, merchantInfo.user_pickup_time, twoDays);

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    dispatch(getMethod());
    dispatch(getLogin());
    dispatch(getGuest());
    dispatch(getToken());
    dispatch(getUserId());
    dispatch(getUsername());
    dispatch(getSessionNo());
    dispatch(getCartId())
    dispatch(getCartOwner())
    dispatch(getVoucherPopup())
    dispatch(setPaymentMethod({}));
    dispatch(setPickupDate({}))
    dispatch(setPickupTime({}))
  }, []);

  useEffect(() => {
    if(method === 'delivery') {
      dispatch(getDeliveryTime({
        merchant_id: merchantId
      }))
    }
  }, [method])

  useEffect(() => {
    if (token && token_checked && userId && username) {
      const pusher = new Pusher(process.env.REACT_APP_SOCKET_API_KEY, {
        cluster: "ap1",
        authEndpoint: process.env.REACT_APP_SOCKET_API_URL,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            username: username ? username === 'null' ? null : username : null,
          },
        },
      });

      const channel1 = pusher.subscribe("presence-user" + userId);

      var socketId = null;
      pusher.connection.bind("connected", () => {
        socketId = pusher.connection.socket_id;
      });

      channel1.bind("order-paid-event", (data) => {
        if (data) {
          dispatch(setFullLoading(false));

          if (data.data.payment_status && data.data.order_id) {
            if (
              data.data.payment_status === "active" &&
              data.data.order_id !== null
            ) {
              navigate(`/${store}/order?order_id=${data.data.order_id}`);
            }
          }
        }
      });

      return () => {
        channel1.unbind("order-paid-event");
        pusher.disconnect();
      };
    }
  }, [token, token_checked, userId, username, sessionNo]);

  useEffect(() => {
    if(cartId && cartId !== 'null') {
      dispatch(
        getAddOnMenu({
          type: "add on items",
          merchant_id: merchantId,
          cart_id: cartId,
          method: method,
        })
      )
      .unwrap()
      .catch((ex) => {
        if (ex && ex.response?.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              if(errors[item][0] === 'cart_id is invalid. ') {
                localStorage.removeItem('cart_id')
                dispatch(getCartId())
              }
            });
          }
        }
      });
    }
  }, [cartId])

  useEffect(() => {
    if (merchantId) {
      dispatch(
        getCartInfo({
          merchant_id: merchantId,
          order_method: method,
        })
      )
      .unwrap()
      .then((res) => {
        if (res.data.length > 0) {
          localStorage.setItem("cart_id", res.data[0].cart_id);
          if(res.data[0].user_id === parseInt(userId)) {
            localStorage.setItem("cart_owner", true);
          }
          dispatch(getCartId());
          dispatch(getCartOwner());
        }
      })
      .catch((ex) => {})
    }
  }, [merchantId]);

  useEffect(() => {
    if (merchantId && addressId) {
      if (method === "delivery") {
        dispatch(
          getAddressInfo({
            id: addressId,
          })
        )
        .unwrap()
        .then((res) => {
          dispatch(setUpdateCartId(null));
        })
        .catch((ex) => {})
      }
    }
  }, [merchantId, addressId, method]);

  useEffect(() => {
    dispatch(setPaymentStatus(null))
    
    if(method === 'dine in' && merchantInfo.allow_credit_payment === 0 && merchantInfo.allow_online_payment === 0) {
      dispatch(setPaymentMethod({
        name: 'Pay At Counter',
        tenant_id: '',
        provider_id: '',
      }))
    }
  }, []);
  
  useEffect(() => {
    if(!pickUpTime?.time && !pickupDate.date && cartInfo?.scheduled_at && cartInfo?.selfpick_mode) {
      const scheduledDateTime = new Date(cartInfo.scheduled_at);

      const now = dayjs();
      const isToday = now.isSame(scheduledDateTime, 'day');

      const date = scheduledDateTime.getDate();
      const day = isToday ? 'Today' : scheduledDateTime.toDateString().split(' ')[0];
      const fullDate = scheduledDateTime.toISOString().split('T')[0];
      
      const hours = scheduledDateTime.getHours();
      const minutes = scheduledDateTime.getMinutes().toString().padStart(2, '0');
      const timePeriod = hours >= 12 ? 'PM' : 'AM';
      const formattedTime = `${(hours % 12 || 12)}:${minutes} ${timePeriod}`;

      const timeDiffInMinutes = Math.floor((scheduledDateTime - new Date()) / (1000 * 60));
      const hour = Math.floor(timeDiffInMinutes / 60);
      const minute = timeDiffInMinutes % 60;
      const fromNow = timeDiffInMinutes > 0 
        ? `${hour > 0 ? `${hour} hrs ` : ''}${minute > 0 ? `${minute} mins` : ''}` 
        : 'Now';
  
      dispatch(setPickupDate({
        date: date,
        day: day,
        full_date: fullDate
      }))
  
      dispatch(setPickupTime({
        time: formattedTime,
        from_now: fromNow,
        '24hr_format': cartInfo.scheduled_at?.split(' ')[1]?.substring(0, 5),
        asap: cartInfo.selfpick_mode === 'scheduled' ? false : true,
      }))
    }
  }, [pickUpTime, pickupDate, cartInfo])

  useEffect(() => {
    if(token && login === "full" && cartInfo?.total_voucher_count > 0 && cartId && merchantId && voucherPopup === "true" && voucherPopup_checked) {
      dispatch(openVoucherModal())
      localStorage.removeItem('voucher_popup')
      dispatch(getVoucherPopup())
      dispatch(
        getMyPromotion({
          cart_id: cartId,
          merchant_id: merchantId,
          status: "pending",
        })
      )
    }
  }, [voucherPopup_checked, voucherPopup, cartId, merchantId, login, token])

  return (
    <>
      <article className="cart-container">
        <CartHeader method={method} setLoginType={setLoginType} />
        <CartBody setLoginType={setLoginType} pickUpTimeList={pickUpTimeList} />
      </article>
      {isOpenLoginModal && <Login type={loginType} setLoginType={setLoginType} pickUpTimeList={pickUpTimeList} />}
      {isOpenPaymentModal && <SelectPayment setLoginType={setLoginType} pickUpTimeList={pickUpTimeList} />}
      {isOpenVoucherModal && <SelectVoucher />}
      {isOpenTimeModal && <SelectTime />}
      {isOpenInstantTopUpModal && <InstantTopUp />}
      {isOpenPromotionDetailsModal && <PromoDetails />}
      {isOpenStoreInfoModal && <StoreInfo />}
      {isOpenFaqModal && <Faq />}
      {isOpenPlaceOrderModal && <PlaceOrder />}
    </>
  );
}
