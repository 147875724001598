import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Icon } from "@iconify/react";

import { useToast } from "../../../components/common/toast.provider";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import { useDispatch, useSelector } from "react-redux";
import {
  closeSetAddressModal,
  openConfirmModal,
  openSaveAddressModal,
  setAnimationModal,
} from "../../../redux/reducer/modalReducer";
import {
  checkAddress,
  deleteAddress,
  getAddressInfo,
  getAddressList,
} from "../../../redux/reducer/addressReducer";
import {
  getAddressId,
  getCartId,
  getMethod,
  setConfirmItem,
  setShowModalLoading,
} from "../../../redux/reducer/commonReducer";
import {
  getCartInfo,
  setUpdateCartId,
} from "../../../redux/reducer/cartReducer";

import ActionMenu from "../../../components/element/action.menu";

import apiService from "../../../services/api.service";

export default function AddressList() {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { store } = useParams();
  const { pathname } = useLocation();
  const { width } = useWindowDimensions();

  const { cartInfo, pickupDate, pickupTime } = useSelector((state) => state.cart);
  const { addressList, addressListLoading, addressMenu } = useSelector(
    (state) => state.address
  );
  const { cartId, merchantId, method, addressId } = useSelector(
    (state) => state.common
  );

  const handleChangeAddress = (id) => {
    dispatch(setAnimationModal(false));
    dispatch(closeSetAddressModal());
    dispatch(openSaveAddressModal());
    dispatch(
      getAddressInfo({
        id: id,
      })
    );
  };

  const handleDeleteAddress = (id) => {
    dispatch(
      deleteAddress({
        id: id,
      })
    ).then(() => {
      dispatch(getAddressList());
      toast.success("Delete address successful.");
    });
  };

  const handleSelectAddress = (id) => {
    dispatch(setShowModalLoading(true))

    if (pathname === `/${encodeURIComponent(store?.trim())}/cart`) {
      dispatch(setUpdateCartId("address_loading"));
    }

    dispatch(
      checkAddress({
        address_id: id,
        merchant_id: merchantId,
      })
    )
      .unwrap()
      .then(async (res) => {
        dispatch(setShowModalLoading(false))
        dispatch(closeSetAddressModal())

        localStorage.setItem("method", "delivery");
        dispatch(getMethod());

        if (cartId && addressId && id !== parseInt(addressId)) {
          try {
            const response = await apiService.updateCart({
              cart_id: cartId,
              address_id: id,
              promotion_id: cartInfo.promotion_id,
              do_not_use_any: null,
              user_promotion_bag_id: cartInfo.user_promotion_bag_id ?? null,
              ...((method === "take away" || method === 'delivery') && pickupTime?.time && {
                selfpick_mode: pickupTime.asap ? "ASAP" : "scheduled",
                ...((!pickupTime.asap && method === 'delivery') || method === 'take away') && { scheduled_at: `${pickupDate.full_date} ${pickupTime['24hr_format']}:00` }
              })
            });

            if (response) {
              dispatch(
                getCartInfo({
                  merchant_id: merchantId,
                  order_method: method,
                })
              )
              .unwrap()
              .then((res) => {
                localStorage.setItem("address_id", id);
                dispatch(getAddressId());
              })
              .catch((ex) => {})
            }
          } catch (ex) {
            dispatch(setUpdateCartId(null));

            if (ex && Object.keys(ex).length > 0) {
              let errorMsg = [];
              if (ex.response?.status === 422) {
                const errors = ex.response.data.errors;
                if (errors && Object.keys(errors).length > 0) {
                  Object.keys(errors).map((item, i) => {
                    if (
                      errors?.cart &&
                      errors?.cart ===
                        "There is cart that still in payment processing. "
                    ) {
                      dispatch(openConfirmModal());
                      dispatch(
                        setConfirmItem({
                          type: "cart processing",
                          item: cartId,
                        })
                      );
                    } else {
                      errorMsg = errors[item][0];
                      toast.error(errorMsg);
                    }
                  });
                }
              }

              if (ex.response?.status === 404) {
                localStorage.removeItem("cart_id");
                dispatch(getCartId())
                toast.error('Your cart has been outdated. Please refresh.');
               
                setTimeout(() => {
                  window.location.reload()
                  // navigate(`/${store}/menu`);
                }, 1000)
              }
            }
          }

          return;
        }

        dispatch(setUpdateCartId(null))
        localStorage.setItem("address_id", id);
        dispatch(getAddressId());

        if (pathname === `/${encodeURIComponent(store?.trim())}`) {
          navigate(`/${store}/menu`);
        }
      })
      .catch((ex) => {
        dispatch(setShowModalLoading(false))

        if (cartId && id !== parseInt(addressId)) {
          dispatch(setUpdateCartId(null));
        } else if (pathname !== `/${encodeURIComponent(store?.trim())}/cart`) {
          navigate(`/${store}`);
        }

        if (ex && ex.response?.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              toast.error(errors[item][0]);

              if (
                errors[item][0] ===
                "Merchant has not setup delyva key or customer id. "
              ) {
                navigate(`/${store}`);
                dispatch(closeSetAddressModal());
              }
            });
          }
        }
      });
  };

  return (
    <section className="row address-list">
      <h2>Saved Address <span>(select an address)</span></h2>
      <article className="address-section">
        {!addressListLoading && addressList?.length > 0 ? (
          <>
            {addressList.map((address, addressIndex) => (
              <article className="address-row" key={addressIndex}>
                <article
                  className="col-9 pointer"
                  onClick={() => handleSelectAddress(address.id)}
                >
                  <h3>{address.name}</h3>
                  <p className="">{address.address}</p>
                </article>
                <article className="col-3 flex justify-end items-center relative">
                  <ActionMenu
                    menus={addressMenu}
                    left={width > 991 ? -11 : ""}
                    handleEdit={handleChangeAddress}
                    handleDelete={handleDeleteAddress}
                    item={address.id}
                  >
                    <Icon
                      icon="bi:three-dots-vertical"
                      color="#3C387F"
                      width="18"
                      height="20"
                      className="pointer"
                    />
                  </ActionMenu>
                </article>
                <div className="mb-4" />
              </article>
            ))}
          </>
        ) : addressListLoading ? (
          <article className="general-loading mt-5">
            <Icon icon="svg-spinners:12-dots-scale-rotate" />
          </article>
        ) : (
          <article className="empty-address">
            <Icon icon="carbon:location" color="#9EBCD8" />
            <p>
              Add your address and have <br /> your order delivered to your
              doorstep
            </p>
          </article>
        )}
      </article>
    </section>
  );
}

// Array.from({ length: 3 }, (_, addressListIndex) => (
//   <MDBRow className="mt-4 flex addresses">
//     <div className="col-9 pointer">
//       <label className="_label --address-name pointer">
//         <Skeleton
//           width="8em"
//           height="1.2em"
//           className="blue-skeleton"
//         />
//       </label>
//       <div className="_label --address mt-3 mb-2">
//         <Skeleton
//           width="100%"
//           height="0.9em"
//           className="blue-skeleton"

//         />
//         <Skeleton
//           width="80%"
//           height="0.9em"
//           className="mt-3 blue-skeleton"
//         />
//       </div>
//     </div>
//     <div className="col-3 flex justify-end items-center relative">

//     </div>
//     <div className="mb-4" />
//   </MDBRow>
// ))
