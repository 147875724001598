import { useState } from "react";

//lib
import { Field, useFormikContext } from "formik";
import { MDBCol, MDBInput, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { DatePicker, TimePicker } from "antd";
import dayjs from 'dayjs';
import moment from "moment/moment";

export default function Input(props) {
  const {
    label,
    type,
    name,
    value,
    disabled,
    placeholder,
    verification,
    className,
    availableTime,
    handleVerification,
    as,
    autoFocus,
    inputMode,
    maxLength,
    onChange,
    isRequired,
    inputClassName,
    onClick,
    readOnly,
    onKeyDown, 
    ref,
    handleSendOtp,
    firstTimeRequest,
    isRequesting,
    otpCountDown,
    errorStatus,
    formatTime,
    noSlotAvailable
  } = props;

  const { themeColor, merchantInfoLoading, merchantInfo } = useSelector((state) => state.merchant);
  const { timeLoading } = useSelector((state) => state.reserve);

  const { values, errors, setFieldValue, setFieldError } = useFormikContext();

  const [inputFocus, setInputFocus] = useState(false);

  const disabledDate = (current) => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const minDate = new Date(currentYear - 12, 11, 31);

    return current && current.valueOf() > today.setHours(23, 59, 59, 999) || current.valueOf() > minDate;
  };

  const disabledReserveDate = (current) => {
    const today = moment().startOf('day');
    const startDate = merchantInfo.accept_reservation_today === 1 ? today : today.add(1, 'day');
    const daysFromNow = moment().add(merchantInfo.advance_reservation_day ?? 0, 'days').endOf('day');
  
    const unavailableDays = {
      0: merchantInfo.is_unavailable_on_sunday,
      1: merchantInfo.is_unavailable_on_monday,
      2: merchantInfo.is_unavailable_on_tuesday,
      3: merchantInfo.is_unavailable_on_wednesday,
      4: merchantInfo.is_unavailable_on_thursday,
      5: merchantInfo.is_unavailable_on_friday,
      6: merchantInfo.is_unavailable_on_saturday
    };
  
    const isUnavailableDay = current && unavailableDays[current.day()] === 1;
    const isOutsideAllowedDateRange = current && (current.isBefore(startDate, 'day') || current.isAfter(daysFromNow, 'day'));
  
    return isOutsideAllowedDateRange || isUnavailableDay;
  };

  const disabledTime = () => {
    const allAvailableTimes = availableTime?.length > 0 && availableTime.flatMap(entry => entry.available_times);
    const activeHours = new Set();
    const allHours = Array.from({ length: 24 }, (_, i) => i);
    let disabledMinutes = [];

    allAvailableTimes?.length > 0 && allAvailableTimes.forEach(slot => {
      const startHour = dayjs(slot.from_time, 'HH:mm:ss').hour();
      const endHour = dayjs(slot.to_time, 'HH:mm:ss').hour();
      for (let hour = startHour; hour <= endHour; hour++) {
        activeHours.add(hour);
      }
    });

    const disabledHours = () => allHours.filter(hour => !activeHours.has(hour));

    if (disabledHours().length === 24) {
      disabledMinutes = Array.from({ length: 60 }, (_, i) => i);
    }

    return {
      disabledHours,
      disabledMinutes: () => disabledMinutes
    };
  };

  const handleFocus = () => {
    setInputFocus(true);
  };

  const handleBlur = () => {
    setInputFocus(false);
  };

  const dateValue = values && values[name] ? dayjs(values[name], 'DD-MM-YYYY') : null;
  const timeValue = values && values[name] ? dayjs(values[name], 'HH:mm') : null;

  const formatDate = (value) => {
    const formattedDate = value.replace(/-/g, '/');

    return formattedDate
  };      

  const handleDateChange = (date, dateString) => {
    setFieldValue('reservation_time', '')

    if (date && date.isValid()) {
      setFieldValue(name, formatDate(dateString))
    } else {
      setFieldValue(name, '')
    }
  };

  const handleTimeChange = (time, timeString) => {
    if (time && time.isValid()) {
      setFieldValue(name, timeString)
    } else {
      setFieldValue(name, '')
    }
  };

  return (
    <>
    <MDBCol
      className={`
        element _text 
        ${errors && errors[name] ? '--error mb-0' 
        : !as ? 'mb-4' : 'mb-4'} 
        ${className ? className : ''} 
      `}
      //change input focus border color
    >
      {label && (
        <MDBRow>
          <label>{isRequired && <span>* </span>}{label}</label>
        </MDBRow>
      )}
      <MDBRow
        className={`element _text ${ as ? `${as} ${inputClassName ? inputClassName : ''} ${disabled ? '--disabled' : ''}` : `mdb-field ${inputClassName ? inputClassName : ''} ${disabled ? '--disabled' : ''}` }`}
        style={{ 
          border: !values[name] && inputClassName !== 'phone_no' && inputClassName !== 'phone_no_otp' ?
              errors && errors[name] 
              ? "0.2rem solid #ff313154" 
              : !as ? `0.2rem solid ${merchantInfoLoading === 'finished' ? themeColor.second_color : '#D8D7E5'}`
              : ''
          : !as ? '' : '',
          background: values[name] && as !== 'textarea'
          ? "transparent" 
          : "transparent"
        }}
        >
        {as === 'textarea' && inputClassName === 'special'?
          <Field
              onFocus={handleFocus}
              onBlur={handleBlur}

              type={type ? type : "text"}
              name={name}
              values={values[name]}
              placeholder={placeholder}
              disabled={disabled}
              as={'textarea'}

              className="focus"
              innerref={autoFocus ? autoFocus : undefined}
              inputMode={inputMode ? inputMode : ""}
              maxLength={maxLength ? maxLength : ''}
              onChange={onChange ? onChange : undefined} 
            />
        : as === 'withdraw-field' ?
          <Field
              onFocus={handleFocus}
              onBlur={handleBlur}

              type={type ? type : "text"}
              name={name}
              values={values[name]}
              placeholder={placeholder}
              disabled={disabled}
              className="focus"
              // innerref={autoFocus}
              inputMode={inputMode ? inputMode : ""}
              maxLength={maxLength ? maxLength : ''}
              onChange={onChange ? onChange : undefined} 
              onKeyPress={onKeyDown ? onKeyDown : undefined}
          />
        : as ?
          inputClassName === 'phone_no' || inputClassName === 'phone_no_otp' ?
            <div className="with-otp">
              <article className="prefix">
                {(inputClassName === 'phone_no' || inputClassName === 'phone_no_otp') && 
                  <article style={{ padding: '0.6em 0em 0.5em'}} >
                    <label>+60</label>
                  </article>
                }
                <Field
                    onFocus={handleFocus}
                    onBlur={handleBlur}

                    name={name}
                    disabled={disabled}
                    values={values[name]}
                    placeholder={placeholder}
                    type={type ? type : "text"}
                    as={as === 'textarea' ? as : ''}
                    className={inputClassName ? inputClassName : "focus"}
                    innerref={autoFocus ? autoFocus : undefined}
                    inputMode={inputMode ? inputMode : ""}
                    maxLength={maxLength ? maxLength : ''}
                />
              </article>
              {inputClassName === 'phone_no_otp' ?
                <section className={`request-otp ${isRequesting ? '--no-underline' : !values.username || errors['username'] ? '--disable' : ''}`}
                  onClick={() => {
                    if(values.username && errors && !errors['username'] && !isRequesting && (otpCountDown === null && errorStatus !== "false")) {
                      handleSendOtp({errors, values, setFieldError, setFieldValue})
                    }
                  }}
                >
                    {isRequesting ? (
                        <article className="flex items-center">
                          <Icon icon="line-md:loading-twotone-loop" /> 
                          Requesting
                        </article>
                    ) : otpCountDown === null && firstTimeRequest ? (
                        "Request OTP"
                    ) : otpCountDown === null && !firstTimeRequest ? (
                        "Resend OTP"
                    ) : (
                        `Resend ${formatTime}`
                    )}
                </section> : ''
              }
            </div>
          : 
            <Field
                onFocus={handleFocus}
                onBlur={handleBlur}

                name={name}
                disabled={disabled}
                values={values[name]}
                placeholder={placeholder}
                type={type ? type : "text"}
                as={as === 'textarea' ? as : ''}
                className={inputClassName ? inputClassName : "focus"}
                innerref={autoFocus ? autoFocus : undefined}
                inputMode={inputMode ? inputMode : ""}
                maxLength={maxLength ? maxLength : ''}
            />
        : type === 'date' || type === 'reserve-date' ?
          <article className="flex items-center relative">
            <Field
              readOnly
              onFocus={handleFocus}
              onBlur={handleBlur}
              label={
                <label>
                    {placeholder}
                    {isRequired && <span style={{ color: '#EE5B5B' }}> *</span>}
                </label>
              }
              disabled={disabled}
              as={MDBInput}
              className="focus date-input"
              innerref={autoFocus ? autoFocus : undefined}
              inputMode={inputMode ? inputMode : ""}
              maxLength={10}
            />
            <DatePicker
              // picker="year"
              inputReadOnly
              value={dateValue}
              format='DD-MM-YYYY'
              placeholder="DD-MM-YYYY"
              onChange={handleDateChange}
              disabledDate={type === 'date' ? disabledDate : disabledReserveDate}
            />
          </article>
        : type === 'time' ?
          <article className="flex items-center relative">
            <Field
              readOnly
              onFocus={handleFocus}
              onBlur={handleBlur}
              label={
                <label>
                  {placeholder}
                  {isRequired && <span style={{ color: '#EE5B5B' }}> *</span>}
                </label>
              }
              disabled={disabled}
              as={MDBInput}
              className="focus date-input"
              innerref={autoFocus ? autoFocus : undefined}
              inputMode={inputMode ? inputMode : ""}
              maxLength={10}
            />
            <TimePicker
              inputReadOnly
              value={timeValue}
              onChange={handleTimeChange}
              placeholder="--:--"
              format='HH:mm'
              disabled={timeLoading}
              disabledTime={disabledTime}
              minuteStep={15}
              showNow={false}
              // disabledDate={disabledDate}
            />
          </article>
        :
          <Field
            onFocus={handleFocus}
            onBlur={handleBlur}
            type={type ? type : "text"}
            name={name}
            value={value || values[name]}
            label={
              <label
              //  style={{ color: (values[name] || inputFocus) && !inputClassName ? '#333333' : '#9EBCD8', fontFamily: 'poppins-medium' }}
              >
                  {placeholder}
                  {isRequired && <span style={{ color: '#EE5B5B' }}> *</span>}
              </label>
            }
            disabled={disabled}
            as={MDBInput}
            className="focus"
            innerref={autoFocus ? autoFocus : undefined}
            inputMode={inputMode ? inputMode : ""}
            maxLength={maxLength ? maxLength : ''}
            onClick={onClick ? onClick : undefined} 
            readOnly={readOnly ? readOnly : ''}
            onKeyPress={(e) => {
              if(type === "number") {
                  const allowedRegex = /^[a-zA-Z1-9]+$/;
                  if (!allowedRegex.test(e.key)) {
                      e.preventDefault();
                  }
              }
            }}
          />
        }  
        {verification && (
          <div className="verification-button">
            <button type="button" onClick={() => handleVerification()}>
              SEND
            </button>
          </div>
        )} 
      </MDBRow>
    </MDBCol>
      {errors && errors[name] && as !== 'withdraw-field' && type !== 'code' && name !== "adult_pax" ? (
        <div
          className={`element _errors text-right no-padding error-message ${!as ? 'ps-2' : as === 'round-field' ? 'ps-3' : ''}`}
        >
          {errors[name]}
        </div>
      ) : null}
    </>
  );
}
