import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getCartId, setConfirmItem } from "../../../redux/reducer/commonReducer";
import {
  openConfirmModal,
  openLoginModal,
} from "../../../redux/reducer/modalReducer";

import MenuSearch from "./menu.search";
import PageHeader from "../../../components/header/page.header";

export default function MenuHeader({ setLoginType }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { store } = useParams();

  const { merchantInfo } = useSelector((state) => state.merchant);
  const { userId, login, guest, cartId, cartOwner } = useSelector((state) => state.common);
  const { showRecommend, menuListLoading, cartOwnerId } = useSelector(
    (state) => state.menu
  );

  const backToHome = () => {
    if (cartId && cartOwner && cartOwner === 'true') {
      dispatch(openConfirmModal());
      dispatch(
        setConfirmItem({
          type: "leaving menu",
        })
      );
    } else {
      navigate(`/${store}`);
    }
  };

  const navToCart = () => {
    navigate(`/${store}/cart`);
  };

  const navToOrder = () => {
    if (login === "partial" || login === "full" || guest === "true") {
      navigate(`/${store}/order`);
    } else {
      dispatch(openLoginModal());
      setLoginType("partial");
    }
  };

  function getOpenCloseTimes(timeSlots) {
    const openAtTimes = timeSlots?.length > 0 && timeSlots.map(slot => slot.split('-')[0]);
    const closeAtTimes = timeSlots?.length > 0 && timeSlots.map(slot => slot.split('-')[1]);

    if(openAtTimes && closeAtTimes) {
      const earliestOpenAt = openAtTimes.reduce((earliest, current) => earliest < current ? earliest : current);
  
      let latestCloseAt = closeAtTimes.reduce((latest, current) => {
          if (current === "00:00" || latest === "00:00") {
              return "00:00";
          }
          return latest > current ? latest : current;
      });
  
      return `${earliestOpenAt} - ${latestCloseAt}`;
    }
  }

  const operatingHour = getOpenCloseTimes(merchantInfo.operating_hour)

  return (
    <header
      className={`menu-header ${!showRecommend ? "--hide-recommend" : ""}`}
    >
      <PageHeader
        backIcon="fluent:person-arrow-back-48-regular"
        backTo={backToHome}
        navIcon="solar:cart-plus-broken"
        navTo={navToCart}
        navToOrder={navToOrder}
      />
      <section className={`sub-header`}>
        <article className="flex items-center justify-end gap-2 me-3">
          <Icon icon="line-md:sun-rising-loop" />
          <p>
            <span className="p-sbold">OPEN / CLOSE</span>
            <br /> {operatingHour}{" "}
          </p>
        </article>
        <article className="w-100">
          <MenuSearch />
        </article>
      </section>
    </header>
  );
}
