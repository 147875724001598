import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api.service";

export const getCartInfo = createAsyncThunk('getCartInfo', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getCartInfo(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getCartCount = createAsyncThunk('getCartCount', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getCartCount(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const clearCart = createAsyncThunk('clearCart', async (payload, thunkAPI) => {
  try {
    const response = await apiService.clearCart(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getAllPayments = createAsyncThunk('getAllPayments', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getAllPayments(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getMyPromotion = createAsyncThunk('getMyPromotion', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getMyPromotion(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getAddOnMenu = createAsyncThunk('getAddOnMenu', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getRecommendMenu(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getDeliveryTime = createAsyncThunk('getDeliveryTime', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getDeliveryTime(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const initialData = {
  cartInfo: {},
  cartInfoLoading: false,
  cartCount: 0,

  paymentList: [], 
  paymentListLoading: false,
  paymentMethod: '',

  pickupDate: '',
  pickupTime: '',

  editCartData: [],
  myPromotion: [],
  usedPromotion: [],
  addOnMenu: [], 
  updateCartId: null,
  myPromotionLoading: false,
  paymentStatus: null,

  deliveryTime: [],
  deliveryTimeLoading: false,
};

const dataSlice = createSlice({
  name: "cart",
  initialState: initialData,
  reducers: {
    handleChangeQuantity: (state, action) => {
      if(action.payload.type == "increases"){
        for (let i = 0; i <  state.listing.length; i++) {
          if(i == action.payload.index ) {
            void(state.listing[action.payload.index] =
              {...state.listing[action.payload.index],
            quantity:state.listing[action.payload.index].quantity + 1
            } )
          }
        }
      } else if(action.payload.type == "decreases") {
        for (let i = 0; i <  state.listing.length; i++) {
          if(i == action.payload.index ) {
            void(state.listing[action.payload.index] =
              {...state.listing[action.payload.index],
            quantity:state.listing[action.payload.index].quantity - 1
            } )
          }
        }
      }
    },
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload
    },
    setPickupDate: (state, action) => {
      state.pickupDate = action.payload
    },
    setPickupTime: (state, action) => {
      state.pickupTime = action.payload
    },
    setEditCartData: (state, action) => {
      state.editCartData = action.payload
    },
    setCartInfoLoading: (state, action) => {
      state.cartInfoLoading = action.payload
    },
    setUpdateCartId: (state, action) => {
      state.updateCartId = action.payload
    },
    setPaymentStatus: (state, action) => {
      state.paymentStatus = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getCartInfo.pending, (state) => {
      state.cartInfoLoading = true;
    })
    .addCase(getCartInfo.fulfilled, (state, action) => {
      state.cartInfoLoading = false;
      state.cartInfo = action.payload.data[0];
    })
    .addCase(getCartInfo.rejected, (state) => {
      state.cartInfoLoading = false;
    })
    .addCase(getCartCount.pending, (state) => {
      state.loading = true;
    })
    .addCase(getCartCount.fulfilled, (state, action) => {
      state.loading = false;
      state.cartCount = action.payload.data;
    })
    .addCase(getCartCount.rejected, (state) => {
      state.loading = false;
    })
    .addCase(getMyPromotion.pending, (state) => {
      state.myPromotionLoading = true;
    })
    .addCase(getMyPromotion.fulfilled, (state, action) => {
      state.myPromotionLoading = false;

      const myPromotionList = action.payload.data

      if(myPromotionList.promotion_bag?.length > 0 &&
         myPromotionList.promotion_bag[0].current_status === 'completed') {
         state.usedPromotion = myPromotionList
      } else if(myPromotionList.promotion_bag?.length > 0 &&
         myPromotionList.promotion_bag[0].current_status === 'pending' ) {
         state.myPromotion = myPromotionList;
      }
    })
    .addCase(getMyPromotion.rejected, (state) => {
      state.myPromotionLoading = false;
    })
    .addCase(getAllPayments.pending, (state) => {
      state.paymentListLoading = true;
    })
    .addCase(getAllPayments.fulfilled, (state, action) => {
      state.paymentListLoading = false;
      state.paymentList = action.payload.data;
    })
    .addCase(getAllPayments.rejected, (state) => {
      state.paymentListLoading = false;
    })
    .addCase(getAddOnMenu.pending, (state) => {
      state.addOnMenuLoading = true;
    })
    .addCase(getAddOnMenu.fulfilled, (state, action) => {
      state.addOnMenuLoading = false;
      state.addOnMenu = action.payload.data;
    })
    .addCase(getAddOnMenu.rejected, (state) => {
      state.addOnMenuLoading = false;
    })
    .addCase(getDeliveryTime.pending, (state) => {
      state.deliveryTimeLoading = true;
    })
    .addCase(getDeliveryTime.fulfilled, (state, action) => {
      state.deliveryTimeLoading = false;
      state.deliveryTime = action.payload.data;
    })
    .addCase(getDeliveryTime.rejected, (state) => {
      state.deliveryTimeLoading = false;
    });
  }
});

export const { 
  handleChangeQuantity,
  setPaymentMethod,
  setEditCartData,
  setCartInfoLoading,
  setUpdateCartId,
  setPaymentStatus,
  setPickupDate,
  setPickupTime
} = dataSlice.actions;

export default dataSlice.reducer;
