import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DefaultMenu, EmptyCart } from "../../../assets/images";

import Lottie from "lottie-react";
import { Icon } from "@iconify/react";
import { Form, Formik } from "formik";
import { Food } from "../../../assets/lotties";
import { Swiper, SwiperSlide } from "swiper/react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useToast } from "../../../components/common/toast.provider";

import { useDispatch, useSelector } from "react-redux";
import {
  closePlaceOrderModal,
  openConfirmModal,
  openInstantTopUpModal,
  openLoginModal,
  openPaymentModal,
  openPlaceOrderModal,
  openTimeModal,
  openVoucherModal,
} from "../../../redux/reducer/modalReducer";
import {
  getCartId,
  getCartOwner,
  getTopUpPaymentList,
  getVoucherPopup,
  setConfirmItem,
  setFullLoading,
} from "../../../redux/reducer/commonReducer";
import {
  getAddOnMenu,
  getAllPayments,
  getCartInfo,
  getMyPromotion,
  setEditCartData,
  setPaymentStatus,
  setUpdateCartId,
} from "../../../redux/reducer/cartReducer";
import {
  getMenuInfo,
  getRecommendMenu,
  setIsAddingCartId,
  setMenuId,
  setQuantity,
} from "../../../redux/reducer/menuReducer";
import { getWalletBalance } from "../../../redux/reducer/homeReducer";

import MethodInfo from "./method.info";
import Button from "../../../components/element/button";
import InputCount from "../../../components/element/input.count";

import apiService from "../../../services/api.service";
import dayjs from 'dayjs';

export default function CartBody({ setLoginType, pickUpTimeList }) {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { store } = useParams();

  const { 
    method, 
    login, 
    merchantId, 
    cartId, 
    guest, 
    tableId, 
    sessionNo, 
    addressId 
  } = useSelector((state) => state.common);
  const {
    cartInfo,
    cartInfoLoading,
    handleChangeQuantity,
    paymentMethod,
    paymentListLoading,
    myPromotionLoading,
    updateCartId,
    paymentStatus,
    addOnMenu,
    pickupTime,
    pickupDate,
    deliveryTime,
    deliveryTimeLoading
  } = useSelector((state) => state.cart);
  const { walletBalance } = useSelector((state) => state.home);
  const { isAddingCartId } = useSelector((state) => state.menu);
  const { merchantInfo, themeColor } = useSelector((state) => state.merchant);

  const [id, setId] = useState("");
  const [timeLeft, setTimeLeft] = useState('');
  const [isPaying, setIsPaying] = useState(false);

  const checkoutForm = {
    cart_id: cartId,
    tenant_channel_id: paymentMethod.tenant_id ?? "",
    provider_channel_id: paymentMethod.provider_id ?? "",
    promotion_id: "",
    merchant_id: merchantId,
    payment_method:
      paymentMethod.name === "Credit Balance"
        ? "credit"
        : paymentMethod.name === "Pay At Counter"
        ? "pay at counter"
        : "online payment",
    // ...((method === "take away" || method === "delivery") && {
    //   selfpick_mode: pickupTime.asap ? "ASAP" : "scheduled",
    //   ...((!pickupTime.asap && method === 'delivery') || method === 'take away') && {
    //     scheduled_at: pickupTime.time
    //       ? `${pickupDate.full_date} ${pickupTime['24hr_format']}:00`
    //       : pickUpTimeList?.length > 0 && pickUpTimeList[0]?.times?.length > 0
    //       ? `${pickUpTimeList[0]?.full_date} ${pickUpTimeList[0]?.times[0]['24hr_format']}:00`
    //       : ''
    // }})
    ...((method === "take away" || method === "delivery") && {
      selfpick_mode: pickupTime.asap || (!pickupTime.time && method === 'delivery') || 
      (!pickupTime.time && pickUpTimeList?.length > 0 && pickUpTimeList[0]?.times?.length > 0 
      && pickUpTimeList[0]?.times[0]['asap'] && method === 'take away') ? "ASAP" : "scheduled",
      ...((!pickupTime.asap && pickupTime.time && method === 'delivery') || method === 'take away') && {
        scheduled_at: pickupTime.time
          ? `${pickupDate.full_date} ${pickupTime['24hr_format']}:00`
          : method === 'take away' && pickUpTimeList?.length > 0 && pickUpTimeList[0]?.times?.length > 0
          ? `${pickUpTimeList[0]?.full_date} ${pickUpTimeList[0]?.times[0]['24hr_format']}:00`
          : ''
    }})
  };

  const handleSubmit = async ({ values, setFieldError }) => {
    if((timeLeft && method === 'delivery') || method !== 'delivery') {
      if (login === "partial" || login === "full" || (method === 'dine in' && guest === 'true')) {
        if (values.cart_id) {
          if (cartInfo.payment_status === "pending" && !paymentMethod.name) {
            dispatch(
              setConfirmItem({
                type: "cart processing",
                item: values.cart_id,
                total: cartInfo.total_price,
              })
            );
            dispatch(openConfirmModal());
          } else {
            localStorage.setItem("voucher_popup", true)

            if (paymentMethod.name) {
              dispatch(setFullLoading(true));

              try {
                const response = await apiService.createOrder(values);
                if (response) {
                  localStorage.removeItem("cart_id");
                  localStorage.removeItem("cart_owner");
                  dispatch(getCartOwner())

                  if (paymentMethod.name === "Pay At Counter") {
                    toast.success("Order placed successful. Please pay at the counter later.");
                    navigate(`/${store}/order?order_id=${response.data}`);
                    dispatch(setFullLoading(false));
                  } else if (paymentMethod.name === "Credit Balance") {
                    toast.success("Order placed successful.");
                    navigate(`/${store}/order?order_id=${response.data}`);
                    dispatch(setFullLoading(false));
                  } else {
                    if (response.data.url) {
                      window.parent.location.replace(response.data.url);
                    } else if (response.data) {
                      toast.success("Order placed successful.");
                      navigate(`/${store}/order?order_id=${response.data}`);
                      dispatch(setFullLoading(false));
                    } else {
                      toast.error("Oops! Something went wrong unexpectedly. Please try other payment methods.");
                    }
                  }
                }
              } catch (ex) {
                // setIsPaying(false);
                localStorage.removeItem("voucher_popup")
                dispatch(setFullLoading(false));

                if (ex && Object.keys(ex).length > 0) {
                  let errorMsg = [];
                  if (ex.response?.status === 422) {
                    const errors = ex.response.data.errors;
                    if (errors && Object.keys(errors).length > 0) {
                      Object.keys(errors).map((item, i) => {
                        errorMsg = errors[item][0];
                      });

                      toast.error(errorMsg);

                      if(errorMsg === "Insufficient balance. ") {
                        dispatch(
                          getTopUpPaymentList({
                            merchant_id: merchantId,
                            amount: cartInfo.total_price - walletBalance,
                            allow_online_payment: merchantInfo.allow_online_payment,
                          })
                        )
                        .unwrap()
                        .then((res) => {
                          dispatch(openInstantTopUpModal())
                        })
                        .catch((ex) => {                  
                          if (ex && ex.response?.status === 422) {
                            const errors = ex.response.data.errors;
                            if (errors && Object.keys(errors).length > 0) {
                              Object.keys(errors).map((item, i) => {
                                toast.error(errors[item][0]);
                              });
                            }
                          }
                        });

                        return
                      }
                    }
                  }
                }
              }
            } else {
              setIsPaying(true);
              dispatch(setFullLoading(false));
              dispatch(setPaymentStatus('direct-pay'))
              handleSelectPayment(cartId, cartInfo.total_price);
            }
          }
        }
      } else {
        handlePreRegister();
      }
    } else {
      toast.error('Delivery fees have been updated. Please refresh the page to view the latest changes.')
    }
  };

  const handleSelectPayment = (id, total) => {
    if (cartInfo.cart?.length > 0) {
      if (login === "full") {
        dispatch(
          getWalletBalance({
            merchant_id: merchantId,
          })
        );
      }

      dispatch(
        getAllPayments({
          cart_id: id,
          amount: total,
          type: "cart",
          merchant_id: merchantId,
          allow_online_payment: merchantInfo.allow_online_payment,
        })
      )
        .unwrap()
        .then((res) => {
          dispatch(setFullLoading(false));
          dispatch(openPaymentModal());
        })
        .catch((ex) => {
          dispatch(setPaymentStatus(null))
          dispatch(setFullLoading(false));

          if (ex.response?.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                if (item === "cart_id") {
                  toast.error('Your cart has been outdated. Please refresh.');

                  setTimeout(() => {
                    window.location.reload()
                  }, 1000)
                } else {
                  toast.error(errors[item][0])
                }
              });
            }
          }
        });
    }
  };

  const handleSelectVoucher = () => {
    if (login === "full") {
      dispatch(
        getMyPromotion({
          cart_id: cartId,
          merchant_id: merchantId,
          status: "pending",
        })
      )
        .unwrap()
        .then((res) => {
          dispatch(openVoucherModal());
        })
        .catch((ex) => {})
    } else {
      dispatch(openLoginModal());
      setLoginType("full-cart");
    }
  };

  const handlePreRegister = () => {
    dispatch(openLoginModal());

    if (
      cartInfo?.promotion_deduct_amount > 0 &&
      cartInfo?.promotion_applicable_type !== "AI"
    ) {
      setLoginType("full-cart");
    } else {
      setLoginType("partial-cart");
    }
  };

  const handlePlaceOrder = (id) => {
    if(cartId && cartInfo.payment_status === "pending") {
      dispatch(
        setConfirmItem({
          type: "cart processing",
          item: cartId,
          total: cartInfo.total_price,
        })
      );
      dispatch(openConfirmModal());
    } else {
      dispatch(
        getMenuInfo({
          method: method,
          id: id,
        })
      )
      .unwrap()
      .then((res) => {
        dispatch(setMenuId(id));
        dispatch(openPlaceOrderModal());
      })
      .catch((ex) => {
        if (ex && ex.response?.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              toast.error(errors[item][0]);
            });
          }
        }
      });
    }
  };

  const handleDirectPlaceOrder = async (e, id) => {
    e.stopPropagation()

    dispatch(setIsAddingCartId(id))
    dispatch(setUpdateCartId('add-cart'))

    try {
      const response = await apiService.addCart({
        cart_id: cartId,
        menu_id: id,
        quantity: 1,
        remark: '',
        method: method,
        // option: newOption,
        table_id: method === "dine in" ? tableId ? tableId === 'null' ? null : tableId : null : null,
        session_no: method === "dine in" ? sessionNo ? sessionNo === 'null' ? null : sessionNo : null : null,

        ...(method === "delivery" && {
          "address_id": addressId
        }),
      });

      if (response) {
        dispatch(
          getAddOnMenu({
            type: "add on items",
            merchant_id: merchantId,
            cart_id: cartId,
            method: method,
          })
        );
        dispatch(
          getCartInfo({
            merchant_id: merchantId,
            order_method: method,
          })
        )
        .unwrap()
        .then((res) => {
          dispatch(setUpdateCartId(null))
          dispatch(setIsAddingCartId(null))
          toast.success('Add cart successful.')
        })
        .catch((ex) => {})
      }
    } catch (ex) {
      dispatch(setUpdateCartId(null))
      dispatch(setIsAddingCartId(null))

      if (ex && Object.keys(ex).length > 0) {
        let errorMsg = [];
        if (ex.response?.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              errorMsg = errors[item][0];
              toast.error(errorMsg);
            });
          }
        }
      }
    }
  }

  const handleClearCart = (id, total, status) => {
    if (status === "pending") {
      dispatch(
        setConfirmItem({
          type: "cart processing",
          item: id,
          total: total,
        })
      );
    } else {
      dispatch(
        setConfirmItem({
          type: "clear cart",
          item: id,
        })
      );
    }
    dispatch(openConfirmModal());
  };

  const handleEditCartMenu = (cart_item) => {
    if(cartId && cartInfo.payment_status === "pending") {
      dispatch(
        setConfirmItem({
          type: "cart processing",
          item: cartId,
          total: cartInfo.total_price,
        })
      );
      dispatch(openConfirmModal());
    } else {
      dispatch(
        setEditCartData({
          ...cart_item,
          promotion_id: cartInfo.promotion_id,
          user_promotion_bag_id: cartInfo.user_promotion_bag_id,
        })
      );
      dispatch(openPlaceOrderModal());
    }
  };

  useEffect(() => {
    if(cartInfo?.quotation_expired_at && method === 'delivery') {
      const calculateTimeLeft = () => {
        const now = dayjs();
        const expirationTime = dayjs(cartInfo?.quotation_expired_at);
        const difference = expirationTime.diff(now);
  
        if (difference > 0) {
            const hours = Math.floor(difference / (1000 * 60 * 60)) % 24;
            const minutes = Math.floor(difference / (1000 * 60)) % 60;
            const seconds = Math.floor(difference / 1000) % 60;
  
            setTimeLeft(`${minutes.toString().padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`);
        } else {
            setTimeLeft(null); 
        }
      };
  
      const timer = setInterval(() => {
          calculateTimeLeft();
      }, 1000);
  
      return () => clearInterval(timer);
    }
  }, [cartInfo?.quotation_expired_at]);

  useEffect(() => {
    if(!deliveryTimeLoading && deliveryTime?.length > 0 && deliveryTime[0] !== 'ASAP' && !pickupTime.time) {
      toast.error(`Sorry, this merchant is not accepting delivery orders for now`)
      dispatch(openTimeModal())
    }
  }, [deliveryTimeLoading])
  
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={checkoutForm}
        onSubmit={(values, { errors, setFieldError }) => {
          handleSubmit({ values, errors, setFieldError });
        }}
      >
        {({ values }) => (
          <Form>
            <article className="cart-body">
              <MethodInfo 
                method={method} 
                info={merchantInfo} 
                defaultPickupTime={pickUpTimeList?.length > 0 && pickUpTimeList[0]?.times?.length > 0 ? `${pickUpTimeList[0]?.day}, ${pickUpTimeList[0]?.times[0]?.time} ${pickUpTimeList[0]?.times[0]?.asap ? '(ASAP)' : `(${pickUpTimeList[0]?.times[0]?.from_now})`}` : 'Today, ASAP'} 
              />
              {cartId && (
                <>
                  <section className={`dine-in-info mb-3 ${cartInfo?.cart?.length > 0 && method === "dine in" ? '' : 'mt-4'}`}>
                    <h2>Order Details</h2>
                    {cartInfo?.cart?.length > 0 && <Button
                      variant="outline"
                      btnClassName="clear-btn"
                      onClick={() =>
                        handleClearCart(
                          cartId,
                          cartInfo.total_price,
                          cartInfo.payment_status
                        )
                      }
                    >
                      <Icon icon="carbon:shopping-cart-clear" />
                    </Button>}
                  </section>
                  {cartInfo?.cart?.length > 0 && method === "dine in" && (
                    <section className="dine-in-info mb-4">
                      <article className="dine-in-col">
                        <Icon icon="ic:twotone-table-bar" />
                        <h4> {cartInfo?.table_name ?? ""}</h4>
                      </article>
                      <article className="dine-in-col">
                        <Icon icon="line-md:sun-rising-twotone-loop" />
                        <h4>{cartInfo?.time ?? ""}</h4>
                      </article>
                    </section>
                  )}
                </>
              )}
              <section className="cart-listing overflow-hidden">
                {(!cartInfoLoading || updateCartId) &&
                cartInfo?.cart &&
                cartInfo?.cart.length > 0 ? (
                  <article className="cart-content">
                    {cartInfo !== undefined && cartInfo.cart?.length > 0
                      ? cartInfo.cart.map((cart, cartIndex) => {
                          const quantitySum =
                            cartInfo.cart?.length > 0 &&
                            cartInfo.cart.reduce((sum, currentItem) => {
                              if (currentItem.menu_id === cart.menu_id) {
                                return sum + currentItem.quantity;
                              }
                              return sum;
                            }, 0);

                          return (
                            <article key={cartIndex}>
                              <section className="cart-item"> 
                                <article
                                  onClick={() => handleEditCartMenu(cart)}
                                  className="pointer"
                                >
                                  <h2 className="mb-0">{cart.menu_name}</h2>
                                  <article className="cart-option flex items-center">
                                    {cart.details?.length > 0
                                      ? cart.details.map(
                                          (option, optionIndex) => (
                                            <p
                                              className={
                                                cart.remark ? "mb-0" : ""
                                              }
                                              key={optionIndex}
                                            >
                                              {option.name}
                                              {optionIndex !==
                                                cart.details.length - 1 && (
                                                <span>,&nbsp;</span>
                                              )}
                                            </p>
                                          )
                                        )
                                      : ""}
                                  </article>
                                  {cart.remark && <p>** {cart.remark}</p>}
                                  <p className="edit-text">Edit</p>
                                  {/* <Icon
                                    icon="flowbite:edit-outline"
                                    className="edit-icon mt-2"
                                  /> */}
                                </article>
                                <article className="grid center" style={{ 
                                  marginTop: updateCartId === cart.cart_item_id ? '-0.23em' : ''
                                }}>
                                  {updateCartId === cart.cart_item_id ? (
                                    <Icon
                                      icon="eos-icons:three-dots-loading"
                                      className="loading-icon --bigger"
                                      color={themeColor.btn_color}
                                    />
                                  ) : (
                                    <h2 className="mb-1">
                                      RM{" "}
                                      {parseFloat(cart.total_price).toFixed(2)}
                                    </h2>
                                  )}
                                  <InputCount
                                    setId={setId}
                                    cartIndex={cartIndex}
                                    food_id={cart.cart_item_id}
                                    cart_id={cartId}
                                    promo_id={cartInfo.promotion_id}
                                    user_promo_id={cartInfo.user_promotion_bag_id}
                                    index={null}
                                    quantity={cart.quantity}
                                    setQuantity={setQuantity}
                                    handleChangeQuantity={handleChangeQuantity}
                                    remark={cart.remark}
                                    option={cart.details}
                                    balance={
                                      cart.quantity_balance !== null
                                        ? cart.quantity_balance
                                        : undefined
                                    }
                                    max={cart.quantity_balance}
                                    quantitySum={quantitySum}
                                  />
                                </article>
                              </section>
                              {(cart.promotion_free_menu?.menu_name || 
                                cart.promotion_free_credit > 0 ||
                                cart.promotion_free_promotion?.promotion_title) && (
                                <section className="cart-item">
                                  <article>
                                    <h2 className="mb-0">
                                      {cart.promotion_free_menu?.menu_name ||
                                       cart.promotion_free_promotion?.promotion_title || 
                                       'Free Credit'}
                                    </h2>
                                    <article className="cart-option flex items-center gap-2">
                                      <article className="free-tag">
                                        <p className="two-line">
                                          Voucher Applied -{" "}
                                          {cart.promotion_title}
                                        </p>
                                      </article>
                                      <article className="free-tag">
                                        <p>
                                          {cart.promotion_free_menu?.menu_name
                                            ? "Free Menu"
                                          : cart.promotion_free_promotion?.promotion_title
                                            ? "Free Voucher"
                                          : "Free Credit"}
                                        </p>
                                      </article>
                                    </article>
                                  </article>
                                  <article className="grid center">
                                    <h2 className="mb-1">RM 0.00</h2>
                                    <InputCount
                                      disabled={true}
                                      quantity={
                                        cart.promotion_free_menu?.quantity || 1
                                      }
                                    />
                                  </article>
                                </section>
                              )}
                            </article>
                          );
                        })
                      : ""}
                    {(cartInfo.promotion_free_menu?.menu_name || cartInfo.promotion_free_credit > 0 ||
                      cartInfo.promotion_free_promotion?.promotion_title) && (
                      <section className="cart-item">
                        <article>
                          <h2 className="mb-0">
                            {cartInfo.promotion_free_menu?.menu_name ||
                              cartInfo.promotion_free_promotion?.promotion_title || 'Free Credit'}
                          </h2>
                          <article className="cart-option flex items-center gap-1">
                            <article className="free-tag">
                              <p className="two-line">
                                Voucher Applied - {cartInfo.promotion_title}
                              </p>
                            </article>
                            <article className="free-tag">
                              <p>
                                {cartInfo.promotion_free_menu?.menu_name
                                  ? "Free Menu" 
                                : cartInfo.promotion_free_promotion?.promotion_title
                                  ? "Free Voucher"
                                : "Free Credit"}
                              </p>
                            </article>
                          </article>
                        </article>
                        <article className="grid center">
                          <h2 className="mb-1">RM 0.00</h2>
                          <InputCount
                            disabled={true}
                            quantity={
                              cartInfo.promotion_free_menu?.quantity || 1
                            }
                          />
                        </article>
                      </section>
                    )}
                    <section
                      className="cart-select pointer"
                      onClick={() => handleSelectVoucher()}
                    >
                      <article className="flex items-center">
                        <Icon
                          icon="tdesign:discount"
                          className="icon --voucher"
                        />
                        <h4>Voucher</h4>
                      </article>
                      <article
                        className={`${
                          !cartInfo.promotion_id ? "notice-section" : ""
                        } flex items-center gap-3`}
                      >
                        {!myPromotionLoading ? (
                          <>
                            <Icon icon="carbon:ticket" className="icon" />
                            <p>
                              {cartInfo.promotion_id ? 1 : 0} voucher selected
                            </p>
                            <Icon
                              icon="mingcute:right-line"
                              className="icon --right"
                            />
                          </>
                        ) : (
                          <Icon
                            icon="line-md:loading-twotone-loop"
                            color={themeColor.btn_color}
                            className="icon --right"
                          />
                        )}
                      </article>
                    </section>
                    <section className="cart-addon">
                      <p className="also-order">People also ordered</p>
                      <Swiper slidesPerView="auto" grabCursor={true}>
                        {addOnMenu?.length > 0 &&
                          addOnMenu.map((addOn, addOnIndex) => (
                            <SwiperSlide
                              className="pointer"
                              onClick={() =>
                                !isAddingCartId && addOn.has_stock === 1 ? handlePlaceOrder(addOn.menu_id) : undefined
                              }
                              key={addOnIndex}
                            >
                              <article className={`addon-frame ${addOn.has_stock === 0 ? '--out-stock' : (!addOn.picture && merchantInfo.company_logo.length > 0) ? '--store-logo' : ''}`}>
                                {addOn.has_stock === 0 && <article className="out-stock-overlay">
                                  <p>Out Of Stock</p>
                                </article>}
                                <LazyLoadImage
                                  src={addOn.picture || (merchantInfo.company_logo.length > 0 ? merchantInfo.company_logo[0].url : DefaultMenu)}
                                  alt=""
                                />
                              </article>
                              <article className={`addon-info ${addOn.has_stock === 0 ? '--out-stock' : ''}`}>
                                <section className="contents">
                                  <h5>{addOn.menu_name}</h5>
                                  <p>{addOn.description}</p>
                                </section>
                                <section className="flex items-center justify-between mt-4">
                                  <h5>
                                    RM{" "}
                                    {parseFloat(
                                      addOn.promotion_price !== null
                                        ? addOn.promotion_price
                                        : addOn.unit_price
                                    ).toFixed(2)}{" "}
                                    {addOn.promotion_price !== null && (
                                      <span>
                                        {parseFloat(
                                          addOn.unit_price
                                        ).toFixed(2)}
                                      </span>
                                    )}
                                  </h5>
                                  {addOn.has_stock === 1 && <Button 
                                    btnClassName={`add-button ${isAddingCartId === addOn.menu_id ? '--loading' : ''}`}
                                    onClick={(e) => !isAddingCartId && addOn.has_compulsory_food_option === 0 ? handleDirectPlaceOrder(e, addOn.menu_id) : undefined}
                                  >
                                    {isAddingCartId === addOn.menu_id ? <Icon icon="svg-spinners:3-dots-scale" /> : <Icon icon="ic:round-plus" />}
                                  </Button>}
                                </section>
                              </article>
                            </SwiperSlide>
                          ))}
                      </Swiper>
                    </section>
                    <section className="cart-payment">
                      <h2 className="mt-5 mb-4">Payment Details</h2>
                      <section
                        className="cart-select m-0 pointer"
                        onClick={() => {
                          setIsPaying(false)
                          dispatch(setPaymentStatus(null))
                          handleSelectPayment(cartId, cartInfo.total_price)
                        }}
                      >
                        <article className="flex items-center">
                          <Icon
                            icon="fluent:wallet-credit-card-32-regular"
                            className="icon --wallet"
                          />
                          <h4 className="min-width-payment">Select Payment Method</h4>
                        </article>
                        <article className="flex items-center gap-3">
                          {!paymentListLoading ? (
                            <>
                              <p className="truncate-payment">{paymentMethod.name}</p>
                              <article>
                                <Icon
                                  icon="mingcute:right-line"
                                  className="icon --right"
                                />
                              </article>
                            </>
                          ) : (
                            !isPaying ?
                              <Icon
                                icon="line-md:loading-twotone-loop"
                                color={themeColor.btn_color}
                                className="icon --right"
                              />
                            : 
                              <Icon
                                icon="mingcute:right-line"
                                className="icon --right"
                              />
                          )}
                        </article>
                      </section>
                      <section className="cart-price">
                        <article className="cart-col">
                          <p>Subtotal ({cartInfo.cart?.length} Items)</p>
                          {/* {!cartInfoLoading ? ( */}
                          <h5>
                            {cartInfo?.subtotal
                              ? parseFloat(cartInfo?.subtotal).toFixed(2)
                              : "0.00"}
                          </h5>
                          {/* ) : (
                            <Icon
                              icon="eos-icons:three-dots-loading"
                              className="loading-icon --bigger"
                              color={themeColor.btn_color}
                            />
                          )} */}
                        </article>
                        {cartInfo?.promotion_deduct_amount > 0 && (
                          <article className="cart-col">
                            <p>Promotion</p>
                            <h5>
                              -{" "}
                              {cartInfo?.promotion_deduct_amount
                                ? parseFloat(
                                    cartInfo?.promotion_deduct_amount
                                  ).toFixed(2)
                                : "0.00"}
                            </h5>
                          </article>
                        )}
                        {cartInfo?.discount > 0 && (
                          <article className="cart-col">
                            <p>Referral Discount</p>
                            <h5>
                              - {parseFloat(cartInfo?.discount).toFixed(2)}
                            </h5>
                          </article>
                        )}
                        {cartInfo?.sales_service_tax > 0 && (
                          <article className="cart-col">
                            <p>SST (
                              {parseFloat(
                                cartInfo?.sst_percent
                              ).toFixed(0)}
                              %)</p>
                            <h5>
                              {cartInfo?.sales_service_tax
                                ? parseFloat(
                                    cartInfo?.sales_service_tax
                                  ).toFixed(2)
                                : "0.00"}
                            </h5>
                          </article>
                        )}
                        {cartInfo?.service_charge > 0 && (
                          <article className="cart-col">
                            <p>
                              Service Charge (
                              {parseFloat(
                                cartInfo?.service_charge_percent
                              ).toFixed(0)}
                              %)
                            </p>
                            <h5>
                              {cartInfo?.service_charge
                                ? parseFloat(cartInfo?.service_charge).toFixed(
                                    2
                                  )
                                : "0.00"}
                            </h5>
                          </article>
                        )}
                        {method === "delivery" &&
                          cartInfo?.delivery_fees?.total_price > 0 && (
                            <article className="cart-col">
                              <p>Delivery Fee</p>
                              <h5>
                                {cartInfo?.delivery_fees?.total_price
                                  ? parseFloat(
                                      cartInfo?.delivery_fees?.total_price
                                    ).toFixed(2)
                                  : "0.00"}
                              </h5>
                            </article>
                          )}
                        <article className="cart-col">
                          <p>Rounding</p>
                          <h5>
                            {cartInfo?.rounding_adjustment
                              ? parseFloat(
                                  cartInfo?.rounding_adjustment
                                ).toFixed(2)
                              : "0.00"}
                          </h5>
                        </article>
                        {cartInfo?.cashback > 0 && (
                          <article className="cart-cashback">
                            <article>
                              <Icon icon="tabler:rosette-discount-check-filled" />
                            </article>
                            <p>
                              You will earn <span>RM {cartInfo?.cashback
                                ? parseFloat(cartInfo?.cashback).toFixed(2)
                                : "0.00"}</span>
                              &nbsp;cashback from this order.
                            </p>
                          </article>
                        )}
                      </section>
                    </section>
                  </article>
                ) : cartInfoLoading && updateCartId !== 'time_loading' ? (
                  <article
                    className={`loading-cart ${
                      method === "dine in" ? "--dine-in" : ""
                    }`}
                  >
                    <article className="lottie-flex">
                      <article className="lottie-frame --cart">
                        <Lottie animationData={Food} />
                      </article>
                      <article className="flex items-center">
                        <p>Loading</p>
                        <article className="loading-dot ms-2"></article>
                        <article className="loading-dot"></article>
                      </article>
                    </article>
                  </article>
                ) : (
                  <article
                    className={`empty-cart ${
                      method === "dine in" ? "--dine-in" : ""
                    }`}
                  >
                    <section className="grid center">
                      <section className="empty-frame">
                        <LazyLoadImage src={EmptyCart} />
                      </section>
                      <h2>Your Cart is Empty</h2>
                      <p>
                        Looks like you haven't added anything <br />
                        to your cart yet ..
                      </p>
                    </section>
                  </article>
                )}
              </section>
            </article>
            <article className="cart-total-pay">
              {cartInfo !== undefined &&
                cartInfo.cart?.length > 0 &&
                cartInfo?.remainder_amount_get_promotion?.remainder_amount >
                  0 && (
                  <section className="add-more">
                    <article>
                      <Icon icon="mage:star-moving" />
                    </article>
                    <p>
                      Add{" "}
                      <span>
                        RM
                        {parseFloat(
                          Math.ceil(
                            cartInfo?.remainder_amount_get_promotion
                              ?.remainder_amount * 1000
                          ) / 1000
                        ).toFixed(2)}
                      </span>{" "}
                      more to receive{" "}
                      {cartInfo?.remainder_amount_get_promotion
                        ?.better_deduct_price > 0
                        ? `RM
                    ${parseFloat(
                      cartInfo?.remainder_amount_get_promotion
                        ?.better_deduct_price
                    ).toFixed(2)} discount`
                        : cartInfo?.remainder_amount_get_promotion
                            ?.better_promotion_title
                        ? `${cartInfo?.remainder_amount_get_promotion?.better_promotion_type === 'free menu' ? 'free' : ''}
                          ${cartInfo?.remainder_amount_get_promotion?.better_promotion_type === 'free menu' ?
                             cartInfo?.remainder_amount_get_promotion
                              ?.better_promotion_menu :
                            cartInfo?.remainder_amount_get_promotion
                              ?.better_promotion_title
                          }
                    ${
                      cartInfo?.remainder_amount_get_promotion
                        ?.better_promotion_type === "free menu"
                        ? ""
                        : "voucher"
                    }`
                        : ""}
                    </p>
                  </section>
                )}
              <Button
                type="submit"
                btnClassName={`w-100 flex items-center ${method === 'delivery' && (timeLeft === '' || timeLeft) ? 'order-now' : 'order-now-without-time'}`}
                disabled={cartInfoLoading || updateCartId || !cartInfo?.cart?.length > 0 || paymentStatus === 'direct-pay'}
              >
                <article>
                  <h3>
                    Order Now -&nbsp;
                    {!cartInfoLoading && updateCartId === null ? (
                      `RM ${
                        cartInfo?.cart &&
                        cartInfo.total_price &&
                        cartInfo.cart?.length > 0
                          ? parseFloat(cartInfo.total_price).toFixed(2)
                          : "0.00"
                      }`
                    ) : (
                      <Icon
                        icon="line-md:loading-twotone-loop"
                        color="#ffffff"
                        className="loading-icon"
                      />
                    )}
                  </h3>
                  {method === 'delivery' && (timeLeft || timeLeft === '') && <p>Time Remaining {timeLeft === '' ? '02:00' : timeLeft}</p>}
                </article>
                {(method !== 'delivery' && paymentStatus === 'direct-pay' && updateCartId === null) && (
                  <Icon
                    icon="line-md:loading-twotone-loop"
                    color="#ffffff"
                    className="loading-icon ms-2"
                  />
                )}
              </Button>
            </article>
          </Form>
        )}
      </Formik>
    </>
  );
}
