import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api.service";

export const getOrderList = createAsyncThunk('getOrderList', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getOrderList(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getReceiptInfo = createAsyncThunk('getReceiptInfo', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getReceiptInfo(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getPublicReceipt = createAsyncThunk('getPublicReceipt', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getPublicReceipt(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const resubmitRefund = createAsyncThunk('resubmitRefund', async (payload, thunkAPI) => {
  try {
    const response = await apiService.resubmitRefund(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);


export const initialData = {
  orderList: [], 
  refundLoading: false,
  orderListLoading: false,
  receiptInfoLoading: false,
  publicReceiptLoading: false,
  receiptInfo: {},
  publicReceipt: {},
  makePaymentData: {}
};

const dataSlice = createSlice({
  name: "order",
  initialState: initialData,
  reducers: {
    setMakePaymentData: (state, action) => {
      state.makePaymentData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getOrderList.pending, (state) => {
      state.orderListLoading = true;
    })
    .addCase(getOrderList.fulfilled, (state, action) => {
      state.orderListLoading = false;
      state.orderList = action.payload.data;
    })
    .addCase(getOrderList.rejected, (state) => {
      state.orderListLoading = false;
    })

    .addCase(getReceiptInfo.pending, (state) => {
      state.receiptInfoLoading = true;
    })
    .addCase(getReceiptInfo.fulfilled, (state, action) => {
      state.receiptInfoLoading = false;
      state.receiptInfo = action.payload.data;
    })
    .addCase(getReceiptInfo.rejected, (state) => {
      state.receiptInfoLoading = false;
    })

    .addCase(getPublicReceipt.pending, (state) => {
      state.publicReceiptLoading = true;
    })
    .addCase(getPublicReceipt.fulfilled, (state, action) => {
      state.publicReceiptLoading = false;
      state.publicReceipt = action.payload.data;
    })
    .addCase(getPublicReceipt.rejected, (state) => {
      state.publicReceiptLoading = false;
    })

    .addCase(resubmitRefund.pending, (state) => {
      state.refundLoading = true;
    })
    .addCase(resubmitRefund.fulfilled, (state, action) => {
      state.refundLoading = false;
    })
    .addCase(resubmitRefund.rejected, (state) => {
      state.refundLoading = false;
    });
  }
});

export const { 
  setMakePaymentData
} = dataSlice.actions;

export default dataSlice.reducer;
