import { LazyLoadImage } from "react-lazy-load-image-component";
import { DefaultCategory, FullLogo, GreenBg, GreenLogo, MerchantBg, Sample } from "../../../assets/images";
import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import scrollToTop from "../../../helpers/scroll.to.top";
import { getMerchantList, getSupermerchantList } from "../../../redux/reducer/commonReducer";
import InfiniteScroll from "react-infinite-scroll-component";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { Skeleton } from "primereact/skeleton";

export default function SuperMerchantList({ handleScanQR }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { merchant } = useParams()

  const { width, height } = useWindowDimensions()
  let scrollHeight = height - (width > 550 ? (height * 0.4) : (height * 0.25))

  const [length, setLength] = useState(10);
  const [start, setStart] = useState(0);
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  const { supermerchantListLoading, supermerchantList } = useSelector((state) => state.common);

  const currentYear = new Date().getFullYear();

  const handleSelectMerchant = (store) => {
    navigate(`/${store}`)
  }

  useEffect(() => {
    dispatch(getSupermerchantList({
      display_name: merchant
    }))
  }, []);

  useEffect(() => {
    scrollToTop()
  }, [])

  return (
    <section className="merchant-list --branch">
      <header className="list-header">
        <section className="main-header">
          {/* <article className="main-function">
              <article className="pointer" onClick={() => handleScanQR()}>
                  <Icon icon="ant-design:scan-outlined" />
              </article>
              <hr />
              <article className="pointer" onClick={() => handleScanQR()}>
                  <Icon icon="ant-design:scan-outlined" />
              </article>
          </article> */}
          {/* <article className="mt-4">
              <article>
                  <h2>Explore your favourite</h2> 
                  <article className="flex">
                      <h2 className="restaurant-on">restaurants on&nbsp;</h2>
                      <article className="logo-frame">
                          <LazyLoadImage src={FullLogo} />
                      </article>
                  </article>
              </article>
          </article> */}
        </section>
        <Swiper
          spaceBetween={0}
          centeredSlides={true}
          autoplay={{
              delay: 4500,
              disableOnInteraction: false,
          }}
          modules={[Autoplay]}
        >
          {!supermerchantListLoading && supermerchantList?.length > 0 ?
              supermerchantList[0]?.supermerchant?.banner?.length > 0 ? 
              supermerchantList[0].supermerchant.banner.map((banner, bannerIndex) => (
                <SwiperSlide className="home-header" key={bannerIndex}>
                  <img src={banner.url} alt="" />
                </SwiperSlide>
              ))
            :  
              ''
          : 
            <SwiperSlide className="home-header">
              <Skeleton width="100%" height="100%" />
            </SwiperSlide>
          }
        </Swiper>
      </header>
      <article className="list-body">
        {!supermerchantListLoading ?
            supermerchantList?.length > 0 ? 
            supermerchantList.map((merchant, merchantIndex) => (
              <section className="merchant-info pointer" key={merchantIndex} onClick={() => handleSelectMerchant(merchant.display_name)}>
                <article className="store-frame">
                    <LazyLoadImage src={merchant.logo_attachment} />
                </article>
                <article>
                    <h4 className="one-line me-3 w-100">{merchant.store_name}</h4>
                    <h6 className={`tag mb-2 ${merchant.remark === 'closed' ? '--closed' : '' }`}>{merchant.remark === 'available' ? 'Open' : 'Closed'}</h6>
                    <p className="mb-0 two-line">{merchant.description}</p>
                    <article className="rating mt-1">
                        <Icon icon="line-md:map-marker-filled" />
                        <p className="text-capitalize">{merchant.state}</p>
                    </article>
                </article>
              </section>
            ))
          :
            ''
        :
          Array.from({ length: 2 }, (_, merchantSkeletonIndex) => (
            <Skeleton className="merchant-info --skeleton" key={merchantSkeletonIndex} />
          ))
        }
      </article>  
    </section>
  );
}
